import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faInfoCircle, faUserTie } from '@fortawesome/free-solid-svg-icons';
import Bowser from 'bowser';
import { DecodeJWT, UpdateUserNameResponse } from 'src/app/core/models/user';
import { DeviceService } from 'src/app/core/services/device.service';
import { EnvironmentVariableService } from 'src/app/core/services/environment-variable.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LoginService } from 'src/app/core/services/login.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { UtilService } from 'src/app/core/services/util.service';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-register-type-login',
  templateUrl: './register-type-login.component.html',
  styleUrls: ['./register-type-login.component.scss']
})
export class RegisterTypeLoginComponent {
  loaded: boolean = false;
  loadedTempPage: boolean = false;

  faGoogle = faGoogle
  faFacebook = faFacebook
  faInfoCircle = faInfoCircle;
  apiUrl = `${environment.apiUrl}`

  selectedLanguage: any;
  logoLoaded: boolean = false;

  token: string = "";
  logo_128: string = "";
  logo_800:string = "";
  load: Promise<boolean>;
  appName:string = "";

  templateId:number
  guid:string
  usernameForm = this.fb.group({
    userName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(13)]]
});
  constructor(
      @Inject(DOCUMENT) private document: Document,
      public panelManagerService: PanelManagerService,
      public indexedDBService: IndexedDBService,
      public deviceService: DeviceService,
      private languageService: LanguageService,
      private loginService: LoginService,
      public utilService: UtilService,
      private route: ActivatedRoute,
      private environmentVariableService:EnvironmentVariableService,
      private fb: UntypedFormBuilder,
      private messagePanelService: MessagePanelService,
      private storageService: LocalStorageService,


  ) {
      this.callLogoAndAppName();   
      const browser = Bowser.getParser(window.navigator.userAgent);
      let browserInfo = browser.getBrowser();

      if (this.indexedDBService.SupportedBrowsers.includes(browserInfo.name)) {
          this.loaded = true;
          this.loadedTempPage = false;
          return;
      }
      else {
          this.loadedTempPage = true;
          this.loaded = false;
      }
  }

  ngOnInit(): void {
    this.initDynamicRoomParameter()
   }
 
   initDynamicRoomParameter() {
     this.route.params.subscribe(params => {
       this.templateId = parseInt(params['templateId']);
       this.guid = params['guid'];
     });
   }

  callLogoAndAppName(){
      this.environmentVariableService.callEnvironmentVariable().then(res=>{
          this.logo_128 = res.LogoL;
          this.logo_800 = res.LogoXL;
          this.appName=res.UygulamaAdi
          this.load = Promise.resolve(true);
      }) 
  }
  ngOnDestroy(): void {
      this.setSelectedLanguage();
  }

  signInwithGoogle() {
      this.loginService.googleSignInForDynamicRoom(this.templateId,this.guid);
  }

  login() {
    this.loginService.loginForDynamicRoom(this.templateId,this.guid)
  }

  checkUsername() {
    if (this.usernameForm.valid) {
        this.updateUsername();
    }
    else if (!this.usernameForm.get('userName').valid) {
        this.messagePanelService.ShowPopupMessageWithLocalization('UserNameEmptyWarning', 2000);
        return;
    }
    else {
        this.messagePanelService.ShowPopupMessageWithLocalization('invalidForm', 2000);
        return;
    }
}

updateUsername() {
    this.loginService.updateUsername({ 'UserName': this.usernameForm.controls['userName'].value })
        .subscribe((updateUserNameRes: UpdateUserNameResponse) => {
            if (updateUserNameRes.UserNameExist) {
                this.messagePanelService.ShowPopupMessageWithLocalization('user-name-already-exist', 2000);
                return
            }
            if (updateUserNameRes.Success) {
                this.messagePanelService.ShowPopupMessageWithLocalization('SavedSuccess', 2000)
                this.panelManagerService.newUserForDynamicRoomRegisterPopupDisplay = false;
                this.token = updateUserNameRes.Token;
                this.storageService.storageSetItem('token', this.token)
                this.login();
            }
            else {
                this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                return;
            }
        })
}

  openHelpLink() {
      window.open('https://www.seslidunya.com/yardim-merkezi/', '_blank');
  }


  setSelectedLanguage() {
      this.selectedLanguage = this.languageService.languages[0];
  }

  handleChange($event) {
      if ($event.checked === false) {
          this.languageService.setChangedLanguage(this.languageService.languages.find(lang => lang.value === 'english'))
          this.languageService.changeLanguage(this.languageService.languages.find(lang => lang.value === 'english'))
      } else {
          this.languageService.setChangedLanguage(this.languageService.languages.find(lang => lang.value === 'turkish'))
          this.languageService.changeLanguage(this.languageService.languages.find(lang => lang.value === 'turkish'))
      }
  }

  decodeJWT(): DecodeJWT {
      return jwt_decode(this.token);
  }
}
