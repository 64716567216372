import { environment } from './../../../environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'console';
import { promise } from 'protractor';
import { Subject } from 'rxjs';
import { EnvironmentVariable } from '../models/environmentVariables';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentVariableService {

  apiUrl = `${environment.apiUrl}`

  private httpClientEnv: HttpClient;

  sentEnvironmentVariableSource: Subject<EnvironmentVariable> = new Subject();
  sentEnvironmentVariable = this.sentEnvironmentVariableSource.asObservable();

  constructor(private http: HttpClient,
    private handler: HttpBackend) { 
      this.httpClientEnv = new HttpClient(this.handler);
    }


  getEnvironmentVariableByDomainName(domainName: string) {
    let body = { "AlanAdi": domainName }
    return this.http.post<EnvironmentVariable>(this.apiUrl + 'GetOrtamDegiskenleriPublic', body)
  }

  getHtmlElementByLink(link: string) {
    return this.httpClientEnv.get(link,{responseType:'text'})
  }

  callEnvironmentVariable(): Promise<EnvironmentVariable> {
    return new Promise((resolve, reject) => {
      let domainName = window.location.hostname;
      if (domainName.includes('www.')) {
        domainName = domainName.replace('www.', '');
      }
      this.getEnvironmentVariableByDomainName(domainName).subscribe(
        (res) => {
          if (res) {
            this.sentEnvironmentVariableSource.next(res); 
            resolve(res);
          }
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  
  
}
