<div class="public-container">
    <div class="sidebar-container">
        <div class="sidebar">
            <div class="sidebar-header">
                <div class="header-lef">
                    <label (click)="openHelpLink()">{{'help' | translate}}</label>
                </div>
                <div class="header-center" *ngIf="load | async">
                    <img [src]="logo_128 ? logo_128 : 'assets/images/zumti_128x128px.png'" class="site-logo">
                </div>

                <div class="header-right">
                    <p-toggleButton [(ngModel)]="selectedLanguage" (onChange)="handleChange($event)"
                        onLabel="{{ 'turkish' | translate }}" offLabel="{{ 'english' | translate }}"
                        [style]="{ 'background-color': 'transparent', 'border': 'none', 'color': '#dcdcdc'}">
                    </p-toggleButton>
                </div>
            </div>

            <div class="sidebar-content">
                <div class="buttons">
                    <button (click)="signInwithGoogle()" type="button" class="btn btn-primary w-100">
                        <fa-icon [icon]="faGoogle"></fa-icon>
                        {{ 'signInwithGoogle' | translate }}</button>
                </div>
                <div  *ngIf="CanGuestJoin && load | async" class="buttons guest">
                    <button data-testid="fast-login" (click)="signAsGuest()" type="button" class="btn btn-primary w-100">
                        <fa-icon [icon]="faGuest"></fa-icon>
                       {{'fast-login' | translate}}</button>
                </div>
            </div>

        </div>
    </div>
    <div class="app-image" *ngIf="load | async">
        <img [src]="logo_800 ? logo_800 : 'assets/images/zumti_800x578px.png'">
    </div>


    <div class="site-description">
        {{ appName ? appName : 'Zumti' }} tüm cihazlardan (Mobil sohbet, tablet ve PC) sesli ve görüntülü sohbet
        yapabileceğiniz bir uygulamadır. Sohbet odalarında 10 kişiye kadar çoklu mikrofon ve kameralıdır.
    </div>

</div>

<div class="not-supported" *ngIf="loadedTempPage"></div>



<p-dialog [(visible)]="panelManagerService.newUserRegisterPopupDisplay" [baseZIndex]="10000" [modal]="true"
    [closable]="false" header="{{ 'createUsername' | translate }}">

    <form class="username-form" [formGroup]="usernameForm">
        <input type="text" class="new-username" formControlName="userName" placeholder="{{ 'UserName' | translate }}"
            pInputText minlength="3" maxlength="13" />
        <fa-icon [icon]="faInfoCircle" class="faInfoCircle" tooltipZIndex="99999999"
            pTooltip="{{'UserNameCharLimitWarning'|translate}}" tooltipPosition="bottom"></fa-icon>

        <div class="footer">
            <button type="submit" pButton class="ui-button-raised" label="{{ 'save' | translate }}"
                (click)="checkUsername()"></button>
            <button type="button" pButton class="ui-button-raised ui-button-danger" label="{{ 'cancel' | translate }}"
                (click)="panelManagerService.newUserRegisterPopupDisplay = false"></button>
        </div>

    </form>

</p-dialog>