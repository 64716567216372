<div class="main-menu">
    <div class="header">
        <div class="close-button" *ngIf="!deviceService.isMobile" (click)="panelManagerService.closeMainPanel()">
            <fa-icon [icon]="faTimes"></fa-icon>
        </div>

        <div class="user">
            <app-user-photo [userPhotoId]="indexedDBService.userFullInfo.ProfilResmiId" (click)="showRoomOwnerPicture()"
                [width]="deviceService.isMobile?50:80" [height]="deviceService.isMobile?50:80" [showStateIcon]="false"
                class="profile-photo">
            </app-user-photo>
            <div class="update-profile-photo">
                <app-update-profile-photo></app-update-profile-photo>
            </div>
            <div class="user-details">
                <diV class="username-row">
                    <span class="username">{{ indexedDBService.userFullInfo?.KullaniciAdi }}</span>
                    <app-user-id [userId]="indexedDBService.userFullInfo?.KullaniciId"></app-user-id>
                </diV>
                <div class="rank">
                    <small class="rank-name">{{ indexedDBService.userFullInfo?.RutbeAdi === 'User' ? ('NotAuthorized' |
                        translate) :
                        indexedDBService.userFullInfo?.RutbeAdi }}</small>
                    <app-rank [degree]="indexedDBService.userFullInfo?.RutbeDerece" [color]="'#919191'"
                        [size]="deviceService.isMobile ? 8 : 9">
                    </app-rank>
                </div>
                <div class="state-message"  *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" (click)="panelManagerService.updateStateMessagePopupDisplay=true">
                    <small>{{ indexedDBService.userFullInfo?.DurumMesaj !== '' ?
                        indexedDBService.userFullInfo?.DurumMesaj :
                        translateService.instant('stateMessage') }}</small>
                    <fa-icon [icon]="faEdit" class="state-message-edit"></fa-icon>
                </div>
                <div class="admin-panel-state" (click)="openAdminPanel()" *ngIf="indexedDBService.userFullInfo.RutbeDerece > 1 || indexedDBService.UserIsAnyRoomOwner">
                    <small>{{'adminPanel' | translate}}</small>
                    <fa-icon [icon]="faUserTie" class="admin-icon"></fa-icon>
                </div>
                <div class="state-message" *ngIf="indexedDBService.userFullInfo.AuthorizedUserId !== 0">
                    <small> {{ 'AuthorizedUser' | translate }} : {{indexedDBService.userFullInfo.AuthorizedUserName}}</small>
                    <fa-icon [icon]="faUserMinus" class="state-message-edit" (click)="giveUpRank()"></fa-icon>
                </div>
            </div>

        </div>

    </div>

    <p-scrollPanel [style]="{width: '100%', height: 'calc(100% - 120px)' }">
        <div class="menu">
            <div class="title" *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" (click)="openMenu(mainMenuType.State)">
                <img src="{{ selectedStateHeader.icon }}" class="state-icon-shown" />
                <span class="state-text-shown">{{ selectedStateHeader.key | translate }}</span>
                <fa-icon class="faAngleRightDown"
                    [icon]="selectedMainMenuType === mainMenuType.State ? faAngleDown : faAngleRight"></fa-icon>
            </div>
            <div class="menu-content" *ngIf="selectedMainMenuType === mainMenuType.State">
                <div class="menu-item" (click)="selectedState(item)" *ngFor="let item of states">
                    <img src="{{ item.icon }}" class="state-icon" />
                    <span class="state-name">{{ item.key | translate }}</span>
                </div>
            </div>

            <div class="title" (click)="openMenu(mainMenuType.Personal)">
                <fa-icon [icon]="faCogs"></fa-icon>
                <span class="state-text-shown">{{ 'menu_personelsettings' | translate }}</span>
                <fa-icon class="faAngleRightDown"
                    [icon]="selectedMainMenuType === mainMenuType.Personal ? faAngleDown : faAngleRight"></fa-icon>
            </div>

            <div class="menu-content" *ngIf="selectedMainMenuType === mainMenuType.Personal">
                <div class="menu-item"
                    (click)="panelManagerService.viewProfileInfoPopupDisplay=!panelManagerService.viewProfileInfoPopupDisplay">
                    <fa-icon [icon]="faEye"></fa-icon>
                    <span>{{ 'menu_viewmyinfo' | translate }}</span>
                </div>
                <!-- <div class="menu-item" (click)="deleteAccount()">
                    <fa-icon [icon]="faTrash"></fa-icon>
                    <span>{{ 'delete_account' | translate }}</span>
                </div> -->
                <div *ngIf="panelManagerService.viewProfileInfoPopupDisplay">
                    <div class="user-name-change">
                        <div class="user-name">
                            {{indexedDBService.userFullInfo.KullaniciAdi}}
                        </div>
                        <div *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" class="change-section" (click)="panelManagerService.updateUserNamePopupDisplay=true">
                            <span>{{ 'update-user-name' | translate }}</span>
                            <fa-icon [icon]="faEdit" class="state-message-edit ml-2"></fa-icon>
                        </div>
                    </div>
                </div>
                
                <div class="menu-item lang">
                    <fa-icon [icon]="faFlag"></fa-icon>
                    <span>{{ 'menu_language' | translate }}</span>
                </div>

                <div class="menu-item-element">
                    <div>
                        <img src="https://sesliturkiye.azureedge.net/themes/SesliTurkiye/Language_tr_24x24.png"
                            style="margin-right: 5px;">
                        <p-radioButton name="turkish" value="turkish" label="{{ 'turkish' | translate }}"
                            [(ngModel)]="selectedLanguage" (onClick)="setSiteLanguage($event)"></p-radioButton>
                    </div>
                    <div>
                        <img src="https://sesliturkiye.azureedge.net/themes/SesliTurkiye/Language_en_24x24.png"
                            style="margin-right: 5px;">
                        <p-radioButton name="english" value="english" label="{{ 'english' | translate }}"
                            [(ngModel)]="selectedLanguage" (onClick)="setSiteLanguage($event)"></p-radioButton>
                    </div>
                </div>
            </div>

            <div  *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" class="title" (click)="openMenu(mainMenuType.Chat)">
                <fa-icon [icon]="faComments"></fa-icon>
                <span class="state-text-shown">{{ 'menu_chatsettings' | translate }}</span>
                <fa-icon class="faAngleRightDown"
                    [icon]="selectedMainMenuType === mainMenuType.Chat ? faAngleDown : faAngleRight"></fa-icon>
            </div>

            <div class="menu-content" *ngIf="selectedMainMenuType === mainMenuType.Chat">
                <div class="menu-item">
                    <fa-icon [icon]="faUserPlus"></fa-icon>
                    <span>{{ ('friendship_requests' | translate) + ' ' +
                        (indexedDBService.FriendshipRequestActive ?
                        ('welcome' | translate) : ('disabled' | translate))}}</span>
                    <p-inputSwitch [(ngModel)]="indexedDBService.FriendshipRequestActive"
                        (onChange)="friendshipRequestActiveChange($event)" style="margin-left:auto; display: flex;">
                    </p-inputSwitch>
                </div>
                <div class="menu-item">
                    <fa-icon [icon]="faFont"></fa-icon>
                    <span (click)="panelManagerService.changeFontPopupDisplay=true">{{
                        'menu_changefont' | translate
                        }}</span>
                </div>
            </div>

            <div class="title" (click)="openMenu(mainMenuType.MyPeople)">
                <fa-icon [icon]="faUsers"></fa-icon>
                <span class="state-text-shown">{{ 'menu_mypeople' | translate }}</span>
                <fa-icon class="faAngleRightDown"
                    [icon]="selectedMainMenuType === mainMenuType.MyPeople ? faAngleDown : faAngleRight"></fa-icon>
            </div>

            <div class="menu-content" *ngIf="selectedMainMenuType === mainMenuType.MyPeople">
                <div class="menu-item" (click)="panelManagerService.showBlockedFriendsPopupDisplay = true">
                    <fa-icon [icon]="faTimesCircle"></fa-icon>
                    <span>{{ 'menu_showblockedfrineds' | translate }}</span>
                </div>
            </div>


            <div class="title" (click)="openMenu(mainMenuType.Help)">
                <fa-icon [icon]="faQuestionCircle"></fa-icon>
                <span class="state-text-shown">{{ 'menu_aboutus' | translate }}</span>
                <fa-icon class="faAngleRightDown"
                    [icon]="selectedMainMenuType === mainMenuType.Help ? faAngleDown : faAngleRight"></fa-icon>
            </div>

            <div class="menu-content" *ngIf="selectedMainMenuType === mainMenuType.Help">
                <div class="menu-item" (click)="whoWeAreDisplay()">
                    <fa-icon [icon]="faNewspaper"></fa-icon>
                    <span>{{ 'menu_whoareme' | translate }}</span>
                </div>
                <div class="menu-item" (click)="usageAggrementPageDisplay()">
                    <fa-icon [icon]="faEdit"></fa-icon>
                    <span>{{ 'menu_usagecontract' | translate }}</span>
                </div>
                <div class="menu-item" (click)="legalWarningPageDisplay()">
                    <fa-icon [icon]="faBolt"></fa-icon>
                    <span>{{ 'menu_legalwarning' | translate }}</span>
                </div>
            </div>


            <div class="title" (click)="openLink()">
                <fa-icon [icon]="faHandshake"></fa-icon>
                <span class="state-text-shown">{{ 'AuthorizationTrading' | translate }}</span>
            </div>

            <div *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" class="title" (click)="openDeleteAccountRequestPopup()">
                <fa-icon [icon]="faTrash"></fa-icon>
                <span class="state-text-shown">{{ 'account-deletion-request' | translate }}</span>
            </div>

            <div class="title" data-testid="signOut" (click)="signOut()">
                <fa-icon [icon]="faSignOutAlt"></fa-icon>
                <span class="state-text-shown">{{ 'menu_signout' | translate }}</span>
            </div>

            <div class="title menu-footer">
                <div class="version">{{version}}</div>
                <div class="app-icons">
                    <img src="../../../../../assets/images/google-play.png" (click)="utilService.openApp()">
                    <img src="../../../../../assets/images/app-store.png">
                </div>
            </div>
        </div>
    </p-scrollPanel>

</div>