<div class="n-room-list-panel">
    <div *ngIf="deviceService.isMobile" class="n-room-tab-header mobile-top">
        <div class="n-search-bar mobile-n-search-bar">
            <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon" *ngIf="!deviceService.isMobile">
                    <fa-icon [icon]="faSearch"></fa-icon>
                </span>
                <input type="text" autocomplete="off" value="" (keyup)="onkeyUp($event)" pInputText
                    placeholder="{{ 'searchbyroomlist' | translate }}" #searchText class="searchInput">
                <span class="ui-inputgroup-addon cursor" *ngIf="isFiltering" (click)="clearSearchText()">
                    <fa-icon [icon]="faTimes" class="fatimes"></fa-icon>
                </span>
            </div>
        </div>

        <div class="n-room-header-page pagination mobile-pagination">
            <div class="pagination-btn mobile-pagination-btn">          
                <div [ngClass]="{'pagination-button-disabled': roomService.pageIndexForRoomList == 1 ||
                roomService.isClickedRoomPaginationElement || searchKey != '' }" class="arrow left" (click)="previousPage()" tooltipPosition="bottom" pTooltip="{{ 'Bir Önceki Liste' | translate }}">
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                </div>
                <div class="index">
                    {{roomService.pageIndexForRoomList}}
                </div> 
                <div [ngClass]="{'pagination-button-disabled': roomService.pageIndexForRoomList == roomService.allPageCountForRoomList || 
                roomService.isClickedRoomPaginationElement || searchKey != ''}" class="arrow right" (click)="nextPage()" tooltipPosition="bottom" pTooltip="{{ 'Bir Sonraki Liste' | translate }}">
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="n-room-tab-header">
        <div class="n-room-tab-close" (click)="closePanel()" *ngIf="!deviceService.isMobile">
            <fa-icon [icon]="faTimes" class="fatimes"></fa-icon>
        </div>
        <div class="n-room-header" (click)="isFavoriteRoomsTabs=false;  filterList();">{{ (deviceService.isMobile ?
            ('rooms' | translate) : ('allrooms' | translate)) }}
            <div [ngClass]="{'n-room-active-header': !isFavoriteRoomsTabs}"></div>
        </div>
        <div class="divider" *ngIf="!deviceService.isMobile"></div>
        <div class="n-room-header" (click)="isFavoriteRoomsTabs=true;  filterList();">
            <fa-icon [icon]="faHeart" class="favoriteRoomIcon"></fa-icon> {{ (deviceService.isMobile ? ('favourite' |
            translate) : ('favourite' | translate)) + ' ' + (roomService.favoriteRooms?.length > 0 ?
            roomService.favoriteRooms?.length : '') }}
            <div [ngClass]="{'n-room-active-header': isFavoriteRoomsTabs}"></div>
        </div>

        <div *ngIf="!deviceService.isMobile" class="n-room-header-page pagination">
            <div class="pagination-btn">          
                <div [ngClass]="{'pagination-button-disabled': roomService.pageIndexForRoomList == 1 ||
                roomService.isClickedRoomPaginationElement  || searchKey != ''}" class="arrow left" (click)="previousPage()" tooltipPosition="bottom" pTooltip="{{ 'Bir Önceki Liste' | translate }}">
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                </div>
                <div class="index">
                    {{roomService.pageIndexForRoomList}}
                </div> 
                <div [ngClass]="{'pagination-button-disabled': roomService.pageIndexForRoomList == roomService.allPageCountForRoomList || 
                roomService.isClickedRoomPaginationElement  || searchKey != ''}" class="arrow right" (click)="nextPage()" tooltipPosition="bottom" pTooltip="{{ 'Bir Sonraki Liste' | translate }}">
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                </div>
            </div>
        </div>
        <div *ngIf="!deviceService.isMobile" class="n-search-bar">
            <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon" *ngIf="!deviceService.isMobile">
                    <fa-icon [icon]="faSearch"></fa-icon>
                </span>
                <input type="text" autocomplete="off" value="" (keyup)="onkeyUp($event)" pInputText
                    placeholder="{{ 'searchbyroomlist' | translate }}" #searchText class="searchInput">
                <span class="ui-inputgroup-addon cursor" *ngIf="isFiltering" (click)="clearSearchText()">
                    <fa-icon [icon]="faTimes" class="fatimes"></fa-icon>
                </span>
            </div>
        </div>
    </div>

    <p-scrollPanel [style]="{width: '100%', height: deviceService.isMobile ? '100vh' : 'calc(100vh - 4rem)'}">
        <div class="room-list-top">
            <div #roomListDiv class="n-room-list">
                <div class="n-room-cover" [attr.data-testid]="'room-' + room?.I" *ngFor="let room of rooms" [ngClass]="{'button-disabled' : roomService.micAccessButtonDisabled, 
                    'button-enabled': !roomService.micAccessButtonDisabled}"
                    pTooltip="{{ ('roomOwner' | translate) + room?.W + '\n\n' +  room?.M }}" tooltipPosition="bottom">
                    <div class="n-room"
                    [ngStyle]="{ 'color': room?.CO, 'font-family': room?.FF,'background-color': '#333333' }">
                    <div (click)="enterRoom(room?.I)" class="top room-img">
                        <div class="live-badge">live</div>
                        <div *ngIf="room?.II; else defaultImage" class="img" [ngStyle]="{'background-image': 'url(' + roomBackgroundUrl + room.II + '.jpg)'}"></div>
                        <ng-template #defaultImage>
                            <div class="img" [ngStyle]="{'background-image': 'url(../../../../assets/images/default-room-lobi-image.jpg)'}"></div>
                        </ng-template>
                    </div>
                    
                    <div class="bottom">
                        <div *ngIf="!room.HP" class="item1 count">
                            <div class="transparent-bg">
                                <span >{{ roomService.currentRoom ? (room?.I ===
                                    roomService.currentRoom?.Info?.ID ? roomService.roomUserCount : room?.O) :
                                    room?.O }}</span>
                            </div>
                        </div>
                        <div class="item2 name">
                            <div class="room-name">{{ room?.N }}</div>
                        </div>
                        <div class="item3 type">
                            <div>{{ room?.W ? room?.W : roomService.environmentVariable.UygulamaAdi  }}</div>
                        </div> 
                        <div class="item4 eye">
                            <div class="transparent-bg">
                              <fa-icon [icon]="room?.HP ? faLock : faEye" 
                                       [ngClass]="{'faLock': room?.HP, 'faEye': !room?.HP}" 
                                       pTooltip="{{ !room?.HP ? ('ViewRoom' | translate) : '' }}"
                                       (click)="!room?.HP && viewRoom(room?.I, $event)" 
                                       [style.cursor]="room?.HP ? 'not-allowed' : 'pointer'"
                                       tooltipPosition="top"></fa-icon>
                            </div>
                          </div>                          
                    </div>
                   </div>
                    
                </div>
                <div *ngIf="deviceService.isMobile && panelManagerService.roomOpened" [ngDraggable]="true"
                    [inBounds]="true" [bounds]="roomListDiv" class="fixed-bottom-right">
                     <div class="close" (click)="roomService.closeRoomPanel()">x</div>
                    <div class="opened-room">
                        <div (click)="panelManagerService.openRoomPage()"
                            *ngIf="roomService.currentRoom.Info?.BUTTON_BACK_IMAGE_ID; else defaultImageForFixed"
                            class="img-opened"
                            [ngStyle]="{'background-image': 'url(' + roomBackgroundUrl + roomService.currentRoom.Info?.BUTTON_BACK_IMAGE_ID + '.jpg)'}">
                        </div>
                        <ng-template #defaultImageForFixed>
                            <div (click)="panelManagerService.openRoomPage()" class="img-opened"
                                [ngStyle]="{'background-image': 'url(../../../../assets/images/default-room-lobi-image.jpg)'}">
                            </div>
                        </ng-template>
                        <div class="opened-name">{{roomService.currentRoom.Info?.NAME}}</div>
                    </div>
                </div>
            </div>
        </div>
    </p-scrollPanel>
</div>

<app-view-room *ngIf="panelManagerService.viewTheRoomPopupDisplay"></app-view-room>