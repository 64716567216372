export const environment = {
  production: true,
  appVersion: '2.49.10-7e070eef',
  checkVersion: true,
  builtAppHash: '7e070eef5935facd956cb8f8e1ca03070088aad7',
  centrifugoWebsocketUrl: 'wss://centri.zumti.com/connection/websocket',
  jitsiurl: 'meetx.turkpages.com',
  webSiteUrl: 'https://web.seslidunya.com/',
  apiUrl: 'https://v2-be.seslidunya.com/',
  natsUrl: 'wss://centri.zumti.com',
  googleCientId: '590432653135-m4etmnl73b4h37pf4peqv3ik951fesm6.apps.googleusercontent.com',
  livekitUrl:   'wss://live-test.zumti.com',
  customerName: 'seslidunyacom',
};