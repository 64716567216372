import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/core/models/constants';
import { RoomUser, RoomUserContextMenuMode } from 'src/app/core/models/room-user';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';
import { DeviceService } from '../../../../services/device.service';

@Component({
  selector: 'app-user-on-mic',
  templateUrl: './user-on-mic.component.html',
  styleUrls: ['./user-on-mic.component.scss']
})
export class UserOnMicComponent implements OnInit,OnDestroy {

  roomUser?: RoomUser;
  loaded:  boolean = false;

  micLevel: number = 0;
  roomCamIconUrl: string = "";
  roomScreenShareUrl: string = "";
  micLevelChangedSubscription: Subscription;

  constructor(
    public roomService: RoomService,
    public panelManagerService:PanelManagerService,
    public deviceService:DeviceService
  ) {
   }
  ngOnDestroy(): void {
    if (this.micLevelChangedSubscription) {
      this.micLevelChangedSubscription.unsubscribe();
      
    }
  }

  ngOnInit(): void {
    this.loaded = true;
    this.setIcons();
  }
  displayImage(){
    this.panelManagerService.roomAIImagePopupDisplay = true
  }
  setCamIconUrl() {
    if (this.roomUser?.IsCamOpen) {
      this.roomCamIconUrl = Constants.roomCamIconUrl;
    }
    else
      this.roomCamIconUrl = "";
  }
  setShareScreenIconUrl() {
    if (this.roomUser?.IsScreenShareOpen) {
      this.roomScreenShareUrl = "./../../../../../../assets/icons/screen-share.png"
    }
    else{
      this.roomScreenShareUrl = "";
    }
  }

  watchCamera(event:Event) {
    event.stopPropagation();
    this.roomService.watchUserCam(this.roomUser?.KullaniciId);
    this.panelManagerService.showedRoomUserList = false;
  }

  watchScreenShare(event:Event) {
    event.stopPropagation();
    this.roomService.watchUserScreenShare(this.roomUser?.KullaniciId);
  }

  showUserCard() {
    if (this.roomUser.RutbeAdi === "AI Assistant") {
      this.panelManagerService.roomAIImagePopupDisplay = true
      return
    }
    this.roomService.roomUserContextMenuMode = RoomUserContextMenuMode.RoomUserList;
    this.roomService.selectedRoomUser = this.roomUser;
    this.panelManagerService.roomUserCardPopupDisplay = true;
  }
  
  setIcons(){
    this.setCamIconUrl();
    this.setShareScreenIconUrl();
  }
  set(user: RoomUser) {
    this.roomUser = user;
  }
}
