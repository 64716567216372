import { LivekitService } from './../../livekit/services/livekit.service';
import { UserFullInfo } from './../../models/user';
import { Component, OnInit, Output, EventEmitter, HostListener, ElementRef, ViewChild, ViewEncapsulation, Input, ComponentFactoryResolver } from '@angular/core';
import { faTimes, faMinus, faRedoAlt, faMicrophone, faHandPaper, faCamera, faMicrophoneSlash, faHeart, faHeadset, faBars, faCameraRetro, faRecordVinyl, faShare, faPhone, faChevronDown, faChevronUp, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { RoomService } from '../../services/room.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { DeviceService } from 'src/app/core/services/device.service';
import { MainPanels, MicrophoneState, SikayetTipleri, WhoEnterRoomTypes } from 'src/app/core/models/enums';
import { Subscription } from 'rxjs';
import { UtilService } from '../../services/util.service';
import { Constants, JsonFile } from '../../models/constants';
import { CurrentRoom, FavoriteRoom, UserListType } from '../../models/room';
import { ReportService } from '../../services/report.service';
import { EditorContextMenuComponent } from './chat/context-menu/context-menu.component';
import { RoomUser, RoomUserEnum } from '../../models/room-user';
import { IndexedDBService } from '../../services/indexed-db.service';
import { ContextMenuService } from 'ngx-contextmenu';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentVariableService } from '../../services/environment-variable.service';
import { UserOnMicDirective } from './chat/user-on-mic/user-on-mic.directive';
import { UserOnMicComponent } from './chat/user-on-mic/user-on-mic.component';
import { RoomUserListService } from '../../services/room-user-list-service';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss',
    './room.component.tablet.scss'
  ],
  encapsulation: ViewEncapsulation.Emulated
})

export class RoomComponent implements OnInit {
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  display: boolean = true;
  @Output() messageEvent = new EventEmitter<any>();
  @Input() room: any;

  @ViewChild("roomMainDiv") roomMainDiv: ElementRef<HTMLElement>;

  faTimes = faTimes;
  faMinus = faMinus;
  faRedoAlt = faRedoAlt;
  faMicrophone = faMicrophone;
  faHandPaper = faHandPaper;
  faCamera = faCamera;
  faScreenShare=faCameraRetro
  faMicrophoneSlash = faMicrophoneSlash;
  faHeart = faHeart;
  faHeadset = faHeadset;
  faBars = faBars;
  faRecords =faRecordVinyl
  faShare = faShare
  faPhone = faPhone
  whoEnterRoomTypes = WhoEnterRoomTypes;

  roomChatPanelBackground: any;
  roomBack: string = "";

  isFavoriteRoom: boolean = false;
  isEmptyRoomMessage: boolean = false;

  showRoomEditorContextMenu: boolean = false;
  @ViewChild(EditorContextMenuComponent) roomEditorContextMenuClick: EditorContextMenuComponent;

  microphoneState: MicrophoneState = MicrophoneState.Stopped;
  _microphoneState = MicrophoneState;
  roomUser: RoomUser;

  mainPanels = MainPanels;
  constants = Constants;
  micUsersCount: number = 0;
  @ViewChild(UserOnMicDirective, { static: false, read: UserOnMicDirective }) userOnMicHost: UserOnMicDirective;


  microphoneStateChangedSubscription: Subscription;
  roomInfoChangedSubscription: Subscription;
  roomUserListChangedSubscription: Subscription;
  roomMicUserAppendSubscription: Subscription;
  constructor(
    public roomService: RoomService,
    private messagePanelService: MessagePanelService,
    public panelManagerService: PanelManagerService,
    public deviceService: DeviceService,
    public utilService: UtilService,
    public reportService: ReportService,
    public indexedDBService: IndexedDBService,
    public contextMenuService: ContextMenuService,
    public confirmationService: ConfirmationService,
    public translateService: TranslateService,
    public livekitService:LivekitService,
    public environmentVariableService:EnvironmentVariableService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public roomUserListService:RoomUserListService

  ) {

    this.microphoneStateChangedSubscription = this.roomService.microphoneStateChanged.subscribe((microphoneState: MicrophoneState) => {
      this.microphoneState = microphoneState;
    });

    this.roomInfoChangedSubscription = this.roomService.roomInfoUpdatedSource.subscribe((roomInfo: CurrentRoom) => {
      // this.roomService.currentRoom = roomInfo;
      this.setRoomBackground();
      this.setRoomUser();
    });

    this.roomUserListChangedSubscription = this.roomService.micUserListLoaded.subscribe(() => {
      this.refreshRoomMicUserViewList();
    });

    this.roomMicUserAppendSubscription = this.roomService.micUserAdded.subscribe((roomUser:RoomUser) => {
      this.appendToMicUser(roomUser);
    })

    /* this.setRoomBackground();
     this.setRoomUser();
     this.checkFavoriteRoom();*/
  }

  ngOnInit() {
    this.isEmptyRoomMessage = this.utilService.IsNullOrWhitespace(this.roomService.currentRoom.Info?.ODAMESAJ);
    //setTimeout(() => {
    // this.roomMainDiv.nativeElement.focus();
    // this.onFocus(null);
    //});

  }

  setMicUsers() {
    this.roomService.isThereOnMic = (this.roomService.currentMicrophoneJsonStruct.M !== null &&
      this.roomService.currentMicrophoneJsonStruct.M !== undefined &&
      this.roomService.currentMicrophoneJsonStruct.M !== "")

    this.clearMicUsers();

    if (this.roomService.isThereOnMic) {
      let roomUsers: Array<RoomUser> = new Array<RoomUser>();
      roomUsers.push(this.roomService.roomUserList.find((u => u.KullaniciId.toString() === this.roomService.currentMicrophoneJsonStruct.M)))

      if (this.roomService.currentMicrophoneJsonStruct.M2 !== null &&
        this.roomService.currentMicrophoneJsonStruct.M2 !== undefined &&
        this.roomService.currentMicrophoneJsonStruct.M2.length > 0) {
        this.roomService.currentMicrophoneJsonStruct.M2.forEach(userID => {
          roomUsers.push(this.roomService.roomUserList.find((u => u.KullaniciId.toString() === userID)))
        });
      }
      this.micUsersCount = roomUsers.length;
      this.roomService.rowNumberForUserOnMic =Math.floor((this.micUsersCount - 1) / 10) + 1; // 10 user on a row 
      roomUsers.forEach(user => {
        this.appendToMicUser(user);
      })
    }
  }

  refreshRoomMicUserViewList() {
    this.clearMicUsers();
    this.sortByMainMic();
    if (this.roomService.micUserList.length > 0) {
      this.roomService.isThereOnMic = true
      this.micUsersCount = this.roomService.micUserList.length;
      if (this.livekitService.isRoomHasAIAgent) {
        this.micUsersCount = this.micUsersCount + 1
      }
      this.roomService.rowNumberForUserOnMic =Math.floor((this.micUsersCount - 1) / 10) + 1; // 10 user on a row 
      this.roomService.micUserList[0].OnMainMic = true //main mic indicator
      this.roomService.micUserList.forEach(roomUser => {
        this.appendToMicUser(roomUser);
      });
    }
    else{
      this.roomService.rowNumberForUserOnMic = 0
    }

    if (this.livekitService.isRoomHasAIAgent) {
      if (this.roomService.micUserList.length == 0) {
        this.roomService.rowNumberForUserOnMic = 1
        this.micUsersCount = 1
        this.roomService.isThereOnMic = true
        }
      this.appendToMicUser(this.livekitService.roomAIAssistant)
    }
  }
  sortByMainMic() {
    const mainMicUserId = this.roomService.currentMicrophoneJsonStruct.M;
    this.roomService.micUserList.sort((a, b) => {
      if (a.KullaniciId.toString() === mainMicUserId) return -1;
      if (b.KullaniciId.toString() === mainMicUserId) return 1;
      return 0;
    });
  } 

  
  sortMicUserList() {
    this.roomService.micUserList.sort(this.sortByAll.bind(this));
  }

  sortByAll(a: RoomUser, b: RoomUser) {
    var handList = this.roomService.getOldHandUserIdList();
    return this.utilService.sortByBool(a, b, RoomUserEnum[RoomUserEnum.OnMainMic], () => {
      return this.utilService.sortByMultiBool(a, b, RoomUserEnum[RoomUserEnum.OnSecondMic], RoomUserEnum[RoomUserEnum.HandUp], () => {
        return this.utilService.sortByBool(a, b, RoomUserEnum[RoomUserEnum.OnSecondMic], () => {
          return this.utilService.sortByBool(a, b, RoomUserEnum[RoomUserEnum.HandUp], () => {
            return this.utilService.sortByContainsIntArray(a, b, handList, RoomUserEnum[RoomUserEnum.KullaniciId], () => {
              return this.utilService.sortByStringInNotEmptyOrGuidEmpty(a, b, RoomUserEnum[RoomUserEnum.CamSession], () => {
                return this.utilService.sortByIntDescInternal(this.roomUserListService.userViewInRoom[a.KullaniciId]?.rutbeDerece, this.roomUserListService.userViewInRoom[b.KullaniciId]?.rutbeDerece, () => {
                  return this.utilService.sortByInt(a, b, RoomUserEnum[RoomUserEnum.EnterRoomIndex], () => {
                    return 0;
                  });
                });
              });
            });
          });
        });
      });
    });
  }
  listVIPUsers(){
    if (this.roomService.selectedUserListType != UserListType.VIP) {
      this.roomService.pageIndex = 1
      this.roomService.callGetVipUserList()
    }
  }

  listOnlineUsers(){
    if (this.roomService.selectedUserListType != UserListType.ONLINE) {
      this.roomService.pageIndex = 1
      this.roomService.callGetOnlineUserList()
    }
  }

  listHandRequest(){
    if (this.roomService.selectedUserListType != UserListType.REQUEST) {
      this.roomService.pageIndex = 1
      this.roomService.callGetHandRequestUserList()
      this.roomService.isThereHandRequest = false
    }
  }

  appendToMicUser(user: RoomUser) {
    try {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserOnMicComponent);
      let viewContainerRef = this.userOnMicHost.viewContainerRef;
      let componentRef = viewContainerRef.createComponent(componentFactory);
      (<UserOnMicComponent>(componentRef.instance)).set(user);
    } catch (error) {
      console.log(error);
      //window.location.reload();
    }
  }
  clearMicUsers() {
    if (this.userOnMicHost && this.userOnMicHost.viewContainerRef)
      this.userOnMicHost.viewContainerRef.clear();
  }
  toggleStartRecording(){
    this.panelManagerService.controlOfRecordingPopupDisplay = true
  }
  toggleStopRecording(){
    this.roomService.stopRecordRoomTrack()
  }
  ngOnChanges() {
    this.setRoomUser();
    this.checkFavoriteRoom();
    this.setRoomBackground();
  }

  ngOnDestroy() {
    if (this.microphoneStateChangedSubscription)
      this.microphoneStateChangedSubscription.unsubscribe();

    if (this.roomInfoChangedSubscription)
      this.roomInfoChangedSubscription.unsubscribe();

    if (this.roomUserListChangedSubscription) {
      this.roomUserListChangedSubscription.unsubscribe();
    }
    if (this.roomMicUserAppendSubscription) {
      this.roomMicUserAppendSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.roomMainDiv.nativeElement.focus();
    this.onFocus(null);
  }

  firstPage(){
    //ilk sayfadayken ilk sayfaya geçmeye çalışıyoruz
    if (this.roomService.pageIndex == 1) {
      return
    }
    this.roomService.expandUserList(1)
  }
  
  previousPageUserList(){
    //ilk sayfadayken previous olmaz
    if (this.roomService.pageIndex == 1) {
      return
    }
    let index = this.roomService.pageIndex - 1
    this.roomService.expandUserList(index)
  }

  nextPageUserList(){
    //son sayfadayken son sayfaya geçmeye çalışıyoruz
    if (this.roomService.selectedUserListType == UserListType.VIP) {
      if ((this.roomService.pageIndex == Math.floor(this.roomService.vipUserCount / this.roomService.pageSize) +1) || this.roomService.vipUserCount <= this.roomService.pageSize ) {
        return
      }
    }
    if (this.roomService.selectedUserListType == UserListType.ONLINE) {
      if ((this.roomService.pageIndex == Math.floor(this.roomService.roomUserCount / this.roomService.pageSize) +1) || this.roomService.roomUserCount <= this.roomService.pageSize ) {
        return
      }
    }
    if (this.roomService.selectedUserListType == UserListType.REQUEST) {
      if ((this.roomService.pageIndex == Math.floor(this.roomService.requestUserCount / this.roomService.pageSize) +1) || this.roomService.requestUserCount <= this.roomService.pageSize ) {
        return
      }
    }
    let index = this.roomService.pageIndex + 1
    this.roomService.expandUserList(index)
  }

  lastPage(){
    if (this.roomService.selectedUserListType == UserListType.VIP) {
      if ((this.roomService.pageIndex == Math.floor(this.roomService.vipUserCount / this.roomService.pageSize) +1 ) || this.roomService.vipUserCount <= this.roomService.pageSize ) {
        return
      }
      else{
        let index = Math.floor(this.roomService.vipUserCount / this.roomService.pageSize) + 1;
        this.roomService.expandUserList(index)
      }
    }
    else if (this.roomService.selectedUserListType == UserListType.ONLINE) {
      if ((this.roomService.pageIndex == Math.floor(this.roomService.roomUserCount / this.roomService.pageSize) +1 ) || this.roomService.roomUserCount <= this.roomService.pageSize ) {
        return
      }
      else{
        let index = Math.floor(this.roomService.roomUserCount / this.roomService.pageSize) + 1;
        this.roomService.expandUserList(index)
      }
    }
    else{
      if ((this.roomService.pageIndex == Math.floor(this.roomService.requestUserCount / this.roomService.pageSize) +1 ) || this.roomService.requestUserCount <= this.roomService.pageSize ) {
        return
      }
      else{
        let index = Math.floor(this.roomService.requestUserCount / this.roomService.pageSize) + 1;
        this.roomService.expandUserList(index)
      }
    }
  }
  
  copyRoomLink() {
    let domain = "https://" + window.location.hostname;
    let sharableLink = "";
      sharableLink = domain + "/join/roomId/" + this.roomService.currentRoom.Info.ID + "/" + this.roomService.currentRoom.Info.ROOM_PASSWORD;
      navigator.clipboard.writeText(sharableLink)
      .then(() => {
        this.messagePanelService.ShowPopupMessageWithLocalization('copy-room-link-success', 2000);
      });
  }
  
  openPhoneCallingPanel(){
    this.panelManagerService.phoneCallingPopupDisplay = true
  }
  toggleShareMusic(){
    if (this.roomService.isUserAtMainMic() || this.roomService.isUserAtOtherMic()) {
      this.livekitService.toggleShareScreenAudio(true);
    }
    else{
      this.roomService.clickMicrophoneButton(false)
    }
  }

  stopScreenShare(){
    this.roomService.stopScreenShare(this.indexedDBService.userFullInfo.KullaniciId, this.roomService.currentRoom.Info?.ID,false,false)
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.roomService.roomFocused = false;
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: Event): void {
    this.roomService.roomFocused = true;
    this.roomService.messageCount = 0;

  }
  closePanel() {
    this.panelManagerService.roomAIImagePopupDisplay = false;
    if (this.indexedDBService.userFullInfo.RutbeFullInfo.ADI == "AIUser") {
          return
    }
    this.roomService.leaveFromRoomActions(null)
      .then(() => {
        return this.livekitService.disconnectRoom();
      })
      .then(() => {
        this.roomService.roomCameraUserList = [];
      })
      .catch(error => {
        this.messagePanelService.ShowPopupMessageWithLocalization(error, 2000);
      });
  }
  refreshPage(){
    if (this.indexedDBService.userFullInfo.RutbeFullInfo.ADI == "AIUser") {
      return
    }
    this.utilService.refreshPage()
  }
  openRoomRecordPanel() {
    this.panelManagerService.roomRecordingPanelDisplay = true
  }

  hidePanel() {
    this.panelManagerService.hideRoomPage();
  }

  setRoomBackground() {
    this.roomService.userListBackground = {
      'background': this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_1) + 'linear-gradient(to right, ' + this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_1) + ',' + this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_2) + ')'
    };

    if (this.roomService.currentRoom.Info) {
      if (this.utilService.isNullOrEmtpyString(this.roomService.currentRoom.Info?.BACK_IMAGE_ID) || this.roomService.currentRoom.Info?.BACK_IMAGE_ID === this.utilService.GuidEmpty()) {
        this.roomBack = this.utilService.hexToRgb(this.roomService.currentRoom.Info?.RENK_CHAT_1) + ' linear-gradient(to right, ' + this.utilService.hexToRgb(this.roomService.currentRoom.Info?.RENK_CHAT_1) + ', ' + this.utilService.hexToRgb(this.roomService.currentRoom.Info?.RENK_CHAT_2) + ')';
      }
      else {
        this.roomBack = `url('${Constants.veri24MessengerRoomBackImageUrl}backimage_${this.roomService.currentRoom.Info?.BACK_IMAGE_ID.toLowerCase()}.${this.roomService.currentRoom.Info.BackImageType}')`;

        // var http = new XMLHttpRequest();
        // var http1 = new XMLHttpRequest();

        // http.open('HEAD', Constants.veri24MessengerRoomBackImageUrl + 'backimage_' + (this.roomService.currentRoom.Info?.BACK_IMAGE_ID).toLowerCase() + '.jpg', false);
        // http.send();

        // http1.open('HEAD', Constants.veri24MessengerRoomBackImageUrl + 'backimage_' + (this.roomService.currentRoom.Info?.BACK_IMAGE_ID).toLowerCase() + '.gif', false);
        // http1.send();

        // if (http1.status === 200) {
        //   this.roomBack = 'url(' + Constants.veri24MessengerRoomBackImageUrl + 'backimage_' + (this.roomService.currentRoom.Info?.BACK_IMAGE_ID).toLowerCase() + '.gif)';
        // }
        // else {
        //   if (http.status === 200) {
        //     this.roomBack = 'url(' + Constants.veri24MessengerRoomBackImageUrl + 'backimage_' + (this.roomService.currentRoom.Info?.BACK_IMAGE_ID).toLowerCase() + '.jpg)';
        //   }
        //   else {
        //     this.roomBack = this.utilService.hexToRgb(this.roomService.currentRoom.Info?.RENK_CHAT_1) + ' linear-gradient(to right, ' + this.utilService.hexToRgb(this.roomService.currentRoom.Info?.RENK_CHAT_1) + ', ' + this.utilService.hexToRgb(this.roomService.currentRoom.Info?.RENK_CHAT_2) + ')';
        //   }
        // }
      }

    }
  }

  setRoomUser() {
    // this.roomUser = this.roomService.roomUserList.find(f => f.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId);
    this.roomUser = this.roomService.getRoomUserFromActiveListByUserId(this.indexedDBService.userFullInfo.KullaniciId);
  }

  checkFavoriteRoom() {
    if (this.roomService.favoriteRooms?.find((f: FavoriteRoom) => f.OdaId === this.roomService.currentRoom.Info?.ID) !== undefined)
      this.isFavoriteRoom = true;
    else
      this.isFavoriteRoom = false;
  }

  openLiveSupportPanel() {
    this.reportService.insertComplainRequest.ComplainType = SikayetTipleri.LiveSupport;
    this.reportService.insertComplainRequest.Html = "";
    this.panelManagerService.isLiveSupport = true;
    this.reportService.reportPopupDisplay = true;
  }

  openRoomContextMenu($event: MouseEvent): void {
    this.showRoomEditorContextMenu = true;
    setTimeout(() => {
      this.contextMenuService.show.next({
        contextMenu: this.roomEditorContextMenuClick.contextMenu,
        event: $event,
        item: this.indexedDBService.userFullInfo.RutbeFullInfo
      });

    }, 100);

    $event.preventDefault();
    $event.stopPropagation();

  }

  removeFromFavoriteRoom() {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: this.translateService.instant('removeFavoriteRoomWarning'),
      header: this.translateService.instant('Warning'),
      icon: null,
      accept: () => {
        this.roomService.removeFromFavoriteRooms(this.roomService.currentRoom?.Info?.ID).then(() => {
          this.isFavoriteRoom = false;
        }).catch(error => {
        });
        return false;
      },
      reject: () => {
        return false;
      }
    });
  }

  addToFavoriteRoom() {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: this.translateService.instant('addFavoriteRoomWarning'),
      header: this.translateService.instant('Warning'),
      icon: null,
      accept: () => {
        this.roomService.addToFavoriteRooms(this.roomService.currentRoom?.Info?.ID)
          .then(() => {
            this.isFavoriteRoom = true;
          })
          .catch(error => {
          });
        return false;
      },
      reject: () => {
        return false;
      }
    });
  }

  editorTextChange($event) {
  }

  refreshUserList() {
    this.roomService.refreshUserList();
  }

  showRoomOwnerInfoCard() {
    this.roomService.selectedRoomInfo = {
      RoomId: this.roomService.currentRoom.Info.ID,
      RoomName: this.roomService.currentRoom?.Info.NAME,
      OwnerPicture: this.roomService.currentRoom.OwnerInfo.ProfilResmiId
    }
    this.panelManagerService.roomInfoCardPopupDisplay = true;
  }
}