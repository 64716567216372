<div class="user-on-mic-main" (click)="showUserCard()">
    <div class="user-on-mic left">
      <div [attr.id]="'user-on-mic-' + roomUser?.KullaniciId" 
        [ngClass]="{'user-photo': !roomUser.OnMainMic, 'main-mic-photo': roomUser.OnMainMic}" >
      </div>
  
        <div>
            <div *ngIf="deviceService.isMobile" style="padding: 2px;">
                <app-user-photo [userPhotoId]="roomUser?.ProfilResmiId" [width]="45" [height]="45"
                    [showOnlineStateIcon]="false" [showStateIcon]="false">
                </app-user-photo>
            </div>
            <div *ngIf="deviceService.isDesktop" style="padding: 2px;">
                <app-user-photo [userPhotoId]="roomUser?.ProfilResmiId" [width]="40" [height]="40"
                    [showOnlineStateIcon]="false" [showStateIcon]="false">
                </app-user-photo>
            </div>
        </div>
        <small class="username">{{roomUser?.KullaniciAdi}}</small>
        <small 
            [ngClass]="{'red-border': roomUser?.RutbeDerece > 0}" 
            [style.visibility]="roomUser?.RutbeDerece != 0 ? 'visible' : 'hidden'">
            {{ roomUser?.RutbeAdi }}
        </small>

    </div>
    <div class="right">
        <div *ngIf="roomUser.IsCamOpen" class="screenShare-div">
            <img (click)="watchCamera($event)" [attr.src]="roomCamIconUrl" class="cam-img">
        </div>
        <div *ngIf="roomUser.IsScreenShareOpen" class="cam-div">
            <img (click)="watchScreenShare($event)" [attr.src]="roomScreenShareUrl" class="cam-img">
        </div>
    </div>
  </div>
  