<div class="room-editor">
    <div class="emoji-panel" [hidden]="!emojiPanelIsOpen" *ngIf="!deviceService.isMobile" #emojiPanel>
        <emoji-mart set="apple" (emojiClick)="addEmoji($event)"
            [i18n]="{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Recents' } }">
        </emoji-mart>
    </div>

    <div class="editor-panels">
        <app-room-chat-buttons-animations [isDisplay]="animationToggle"></app-room-chat-buttons-animations>
        <app-room-chat-microphone-settings></app-room-chat-microphone-settings>
    </div>

    <div class="editor-actions" [ngClass]="{'can-share-file': roomService.currentRoom.Info.CAN_SHARE_FILE, 'cannot-share-file': !roomService.currentRoom.Info.CAN_SHARE_FILE}">
        <div class="editor-action-button" (click)="toggleAnimationsPanel()" #animation
            pTooltip="{{ 'sendanimation' | translate }}" tooltipPosition="top">
            <fa-icon [icon]="faFilm" class="editor-icon"></fa-icon>
        </div>
        <div class="editor-action-button"
            tooltipPosition="top" [pTooltip]="'copy-room-link'|translate">
            <div class="copy" (click)="copyRoomLink()">
                <fa-icon [icon]="faShare" class="editor-icon"></fa-icon>
            </div>
        </div>
        <div class="editor-action-button" #micIcon (click)="toggleMicSettingsPanel()"
            pTooltip="{{ 'Mikrofon Ayarları (mute)' | translate }}" tooltipPosition="top">
            <fa-icon [icon]="roomService.allParticipantAreMuted ? faVolumeOff : faVolumeUp" class="editor-icon">
            </fa-icon>
        </div>
        <div *ngIf="roomService.currentRoom.Info.CAN_SHARE_FILE" class="editor-action-button" (click)="togglSendFolderPanel()" #animation
             pTooltip="Dosya gönder" tooltipPosition="top">
            <fa-icon [icon]="faFolder" class="editor-icon"></fa-icon> 
        </div>
    </div>
    

    <div class="editor-content">
        <!-- TODO set display is active again when problem is fixed-->
        <fa-icon [icon]="faSmile" class="editor-icon" *ngIf="!deviceService.isMobile" #emojiImg style="display: none;"></fa-icon>

        <div style="width: 100%">
            <p-scrollPanel [style]="{ 'width': '100%' }" class="editor-content-scroll">
                <app-editor (editorTextChanged)="editorTextChange($event)" (editorEnterPressed)="editorEnterPress()"
                    #editor [editorMessageForSet]="editorMessageForSet" [editorOn]="editorOn.Room"></app-editor>
            </p-scrollPanel>
        </div>

        <fa-icon
            [icon]="(roomService.currentMessageSendingType === messageSendingType.Normal ? faPaperPlane : (roomService.currentMessageSendingType === messageSendingType.GenelHerkes) ? faUser : faUserTie)"
            [style.opacity]="sendButtonEnable===true?1:0.3" class="editor-icon" (click)="sendMessageOnRoom()"
            (contextmenu)="onRightClick($event)"></fa-icon>

        <div class="message-selections" #messageSendingTypeMenu *ngIf="isClickRight">
            <div class="message-send-menu">
                <fa-icon [icon]="faPaperPlane" class="editor-icon" pTooltip="{{ 'Normal' | translate }}"
                    tooltipPosition="left" (click)="roomService.selectedSendingType(messageSendingType.Normal)">
                </fa-icon>
                <fa-icon [icon]="faUser" class="editor-icon" pTooltip="{{ 'GenelHerkes' | translate }}"
                    tooltipPosition="left" (click)="roomService.selectedSendingType(messageSendingType.GenelHerkes)">
                </fa-icon>
                <fa-icon [icon]="faUserTie" class="editor-icon" pTooltip="{{ 'GenelAdmin' | translate }}"
                    tooltipPosition="left" (click)="roomService.selectedSendingType(messageSendingType.GenelAdmin)">
                </fa-icon>
            </div>
        </div>
    </div>


    <div class="site-logo">
        <img [src]="roomService.environmentVariable.LogoS ? roomService.environmentVariable.LogoS : 'assets/images/zumti_32x32px.png'">
        <h3>
            {{ roomService.environmentVariable.UygulamaAdi ? roomService.environmentVariable.UygulamaAdi : 'Zumti' }}
          </h3>
          
    </div>

</div>