import { Injectable } from '@angular/core';
import { Constants, Image, User } from 'src/app/core/models/constants';
import { RoomUser } from 'src/app/core/models/room-user';
import { TranslateService } from '@ngx-translate/core';
import { UserFullInfo } from 'src/app/core/models/user';
import { Nicktype } from 'src/app/core/models/nick-type';
import { StateImages } from '../../core/models/state';
import { GetRutbeInfo } from 'src/app/admin/tabs/ranks/models/rank';
import { SqlUser } from 'src/app/admin/models/users';
import { LanguageService } from './language.service';
@Injectable({
    providedIn: 'root'
})
export class UtilService {
    UrlRegex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    avatarCounts: number[] = Array.from({ length: 36 }, (v, k) => k);
    //#region properties 
    TagStart: string = "<LANG>";
    TagEnd: string = "</LANG>";
    //#endregion

    //#region contructor 

    constructor(private translateService: TranslateService,
        public languageService:LanguageService
    ) {

    }

    //#endregion

    //#region variables

    states = [
        { key: 'stateonline', value: 'State_Online', icon: this.getStateIcons('State_Online') },
        { key: 'statebusy', value: 'State_Busy', icon: this.getStateIcons('State_Busy') },
        { key: 'stateoutside', value: 'State_Outside', icon: this.getStateIcons('State_Outside') },
        { key: 'statephone', value: 'State_Phone', icon: this.getStateIcons('State_Phone') },
        { key: 'statehappy', value: 'State_Happy', icon: this.getStateIcons('State_Happy') },
        { key: 'stateolove', value: 'State_Love', icon: this.getStateIcons('State_Love') },
        { key: 'stateangry', value: 'State_Angry', icon: this.getStateIcons('State_Angry') },
        { key: 'statesleep', value: 'State_Sleep', icon: this.getStateIcons('State_Sleep') },
        { key: 'statedontdisturb', value: 'State_DontDisturb', icon: this.getStateIcons('State_DontDisturb') },
        { key: 'stateoffline', value: 'State_Offline', icon: this.getStateIcons('State_Offline') }
    ];



    //#endregion

    //#region null undefined empty controls 


    isNullOrUndefined(value): boolean {
        return value === null || value === undefined || value === "undefined";
    }

    isNullOrEmtpyString(value): boolean {
        return value === null || value === undefined || value === "";
    }

    isNullOrEmtpyObject(value): boolean {
        return value === null || value === undefined || Object.keys(value).length === 0;
    }

    IsNullOrWhitespace(input) {
        if (input === undefined || input === null)
            return true;
        return input.replace(/\s/g, '').length < 1;
    }


    //#endregion

    //#region get value from json operations 

    getIntFromJsonData(value: any): number {
        if (this.isNullOrEmtpyString(value))
            return 0;
        else
            return value;
    }

    getStringFromJsonData(value: any): string {
        if (this.isNullOrUndefined(value))
            return null;
        else
            return value;
    }

    getGuidFromJsonData(value: any): string {

        if (this.isNullOrEmtpyString(value))
            return Constants.guidEmpty;
        else
            return value;
    }

    getBoolFromJsonData(value: any) {
        if (this.isNullOrUndefined(value))
            return false;
        else if (value === "0" || value === 0)
            return false;
        else if (value === "1" || value === 1)
            return true;
        else if (value === "true")
            return true;
        else if (value === "false")
            return false;
        else if (value == true)
            return true
        else
            return false;
    }

    getIdListFromJson(value: any): any[] {
        const idList: any[] = [];
    
        if (this.isNullOrEmtpyString(value)) {
            return idList;
        }
    
        let idlistobject: any;
    
        if (Array.isArray(value)) {
            idlistobject = value[0];
        } else if (typeof value === 'string') {
            try {
                const parsedValue = JSON.parse(value);
                idlistobject = Array.isArray(parsedValue) ? parsedValue[0] : parsedValue;
            } catch (error) {
                console.error('Error parsing JSON string:', error);
                return idList;
            }
        } else {
            idlistobject = value;
        }
    
        if (idlistobject && typeof idlistobject === 'object') {
            Object.keys(idlistobject).forEach(key => {
                idList.push(idlistobject[key]);
            });
        }
    
        return idList;
    }
    


    //#endregion

    //#region sort operations 

    sortByStringInNotEmptyOrGuidEmpty(a, b, propName, nextSortFunc) {

        var aStrHas = this.isNullOrEmtpyString(a[propName]) || a[propName] === Constants.guidEmpty;
        var bStrHas = this.isNullOrEmtpyString(b[propName]) || b[propName] === Constants.guidEmpty;

        if (aStrHas === bStrHas)
            return nextSortFunc();
        else if (aStrHas === false)
            return -1;
        else
            return 1;
    }

    sortByContainsIntArray(a, b, arr, propName, nextSortFunc) {
        return this.sortByContainsIntArrayInternal(a[propName], b[propName], arr, nextSortFunc);
    }

    sortByContainsIntArrayInternal(a, b, arr, nextSortFunc) {
        var aIndex = arr.indexOf(a);
        var bIndex = arr.indexOf(b);

        if (aIndex === bIndex)
            return nextSortFunc();
        else if (aIndex > bIndex)
            return 1;
        else
            return -1;
    }

    sortByBool(a, b, propName, nextSortFunc) {
        var aBool = a[propName];
        if (aBool === undefined)
            aBool = false;

        var bBool = b[propName];
        if (bBool === undefined)
            bBool = false;

        return this.sortByBoolInternal(aBool, bBool, nextSortFunc);
    }

    sortByBoolInternal(a, b, nextSortFunc) {
        if (a === b)
            return nextSortFunc();
        else if (a === true)
            return -1;
        else
            return 1;
    }

    sortByMultiBool(a, b, propName1, propName2, nextSortFunc) {

        var a1Bool = a[propName1];
        if (a1Bool === undefined || a1Bool === null)
            a1Bool = false;

        var a2Bool = a[propName2];
        if (a2Bool === undefined || a2Bool === null)
            a2Bool = false;

        var b1Bool = b[propName1];
        if (b1Bool === undefined || b1Bool === null)
            b1Bool = false;

        var b2Bool = b[propName2];
        if (b2Bool === undefined || b2Bool === null)
            b2Bool = false;

        return this.sortByMultiBoolInternal(a1Bool, b1Bool, a2Bool, b2Bool, nextSortFunc);
    }

    sortByMultiBoolInternal(a1, b1, a2, b2, nextSortFunc) {
        if (a1 === b1 && a2 === b2)
            return nextSortFunc();
        else if (a1 === true && a2 === true)
            return -1;
        else if (b1 === true && b2 === true)
            return 1;
        else
            return nextSortFunc();
    }

    sortByIntDescInternal(a, b, nextSortFunc) {
        if (a === b)
            return nextSortFunc();
        else if (a > b)
            return -1;
        else
            return 1;
    }

    sortByInt(a, b, propName, nextSortFunc) {

        var aInt = a[propName];
        if (aInt === undefined)
            aInt = 99999999;

        var bInt = b[propName];
        if (bInt === undefined)
            bInt = 99999999;

        return this.sortByIntInternal(aInt, bInt, nextSortFunc);
    }

    sortByIntInternal(a, b, nextSortFunc) {
        if (a === b)
            return nextSortFunc();
        else if (a < b)
            return -1;
        else
            return 1;
    }
    //#endregion

    //#region string operations 


    s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    guid() {
        return this.s4() + this.s4() + "-" + this.s4() + "-" + this.s4() + "-" + this.s4() + "-" + this.s4() + this.s4() + this.s4();
    }

    clearRepeatChars2(str) {
        return str.replace(/(.)\1{2,}/g, '$1$1');
    }

    clearRepeatChars(str) {
        return str.replace(/(.)\1{1,}/g, '$1');
    }

    ReplaceAll(str, oldChar, newChar) {

        oldChar = this.PrepareForRegexReplace(oldChar);

        return str.replace(new RegExp(oldChar, "g"), newChar);
    }

    encodeHtml(str) {
        str = this.ReplaceAll(str, "<", "&lt;");
        str = this.ReplaceAll(str, ">", "&gt;");
        return str;
    }

    PrepareForRegexReplace(str) {
        return str.replace(new RegExp("\\[", "g"), "\\[").replace(new RegExp("\\]", "g"), "\\]");
    }

    ClearWhiteSpace(str) {
        return str
            .replace(/ /g, "€_€")
            .replace(/\s+/, "")
            .replace(/\s/g, "")
            .replace(/€_€/g, " ");
    }

    GuidEmpty() {
        return "00000000-0000-0000-0000-000000000000";
    }

    getGuidValue(value) {
        if (this.isNullOrEmtpyObject(value) || value === "") {
            value = this.GuidEmpty();
        }
        return value;
    }
    //#endregion

    //#region Json Data Operations 

    GetNickType(json) {
        if (json === null || json === undefined || json === "") {
            return this.GetDefaultNickType();
        } else {
            if (typeof json === 'object') {
                return json;
            } else {
                // var _json = JSON.parse(json)
                // (_json["C"]).toString().includes("#") === false ? _json["C"] = '#' + _json["C"] : null;
                return JSON.parse(json)
            }
        }
    }

    GetDefaultNickType(): Nicktype {
        return {
            "B": true,
            "C": "black",
            "F": "Segoe UI",
            "I": false,
            "S": "",
            "T": 0 //default style
        } as Nicktype;
    }

    //#endregion


    getEnumKeyString(enumObj, enumValue) {
        var result = Object.keys(enumObj).filter(
            (type) => type === enumObj[enumValue]
        )[0];
        return result;
    }

    GetImageUrl(fileName) {
        return Image.path + fileName;
    }

    getRutbeFontStarts(rank) {
        var html = "";
        for (var i = 0; i < rank; i++)
            html += "<i style=\"font-size:9px;\" class=\"fa fa-star\"></i>";

        return html;
    }


    getProfilePhotoUrl(userImgId, isBigImage) {

        if (this.IsNullOrWhitespace(userImgId) === true)
            return this.GetImageUrl(isBigImage === true ? Constants.Veri24MessengerDefaultUserPhotoBig : Constants.Veri24MessengerDefaultUserPhoto);

        if (userImgId.indexOf(this.GuidEmpty()) === -1)
            return Constants.userPhotoPath + userImgId.toString().toLowerCase() + (isBigImage === true ? "_b" : "") + ".png";

        return this.GetImageUrl(isBigImage === true ? Constants.Veri24MessengerDefaultUserPhotoBig : Constants.Veri24MessengerDefaultUserPhoto);
    }

    parseGetMessageValue(messageStr) {

        try {
            if (!this.isNullOrEmtpyString(messageStr)) {
                var regex = new RegExp(this.TagStart + "(.*?)" + this.TagEnd, "g");
                var regexStartTagReplace = new RegExp(this.TagStart, "g");
                var regexEndTagReplace = new RegExp(this.TagEnd, "g");

                var matchesArray = messageStr.match(regex);
                if (matchesArray !== null && matchesArray !== undefined) {
                    matchesArray.forEach((element, index) => {
                        messageStr = messageStr.replace(
                            new RegExp(element, "g"),
                            this.translateService.instant(element.replace(regexStartTagReplace, "").replace(regexEndTagReplace, ""))
                        );
                    });
                }
                else if (messageStr.split(" ").length === 1) {
                    if (!this.isNullOrEmtpyString(messageStr))
                        messageStr = this.translateService.instant(messageStr);
                }
            }
            return messageStr;
        }
        catch (error) {
            console.log(error);
        }

    }

    // #region hexToRgb
    hexToRgb(hex) { 
        if (hex) {
            let x = [];
            hex = hex.replace('#', '');
    
            if (hex.length != 6) {
                hex = this.modifyHex(hex);
            }
    
            x.push(parseInt(hex.slice(0, 2), 16));
            x.push(parseInt(hex.slice(2, 4), 16));
            x.push(parseInt(hex.slice(4, 6), 16));
    
            return "rgb(" + x.toString() + ")";
        }
       else{
           return "rgb(41,99,115)";
       }
    }

    modifyHex(hex) {
        if (hex.length == 4) {
            hex = hex.replace('#', '');
        }
        if (hex.length == 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        return hex;
    }

    // #endregion


    //#region getKeyWithTag
    getKeyWithTag(message) {
        return this.TagStart + message + this.TagEnd;
    }
    //#endregion

    //#region colorToSigned24Bit
    colorToSigned24Bit(color: string) {
        return ((parseInt(color.substr(1), 16) << 8) / 256);
    }
    //#endregion

    //#region channel operations 
    getUserChannelName(userId) {
        return "/" + Constants.USERCHANNELSTAG + userId;
    }

    getRoomChannelName(roomId) {
        return Constants.ROOMCHANNELSTAG + roomId;
    }

    //#endregion

    //#region date operations 

    addYearToDate(date, year) {
        date.setFullYear(date.getFullYear() + year);
        return date;
    }

    addSecondToDate(date, second) {
        date.setSeconds(date.getSeconds() + second);
        return date;
    }

    addHourToDate(date, hour) {
        date.setHours(date.getHours() + hour);
        return date;
    }

    addDayToDate(date, day) {
        date.setDate(date.getDate() + day);
        return date;
    }

    addMonthToDate(date, day) {
        date.setMonth(date.getMonth() + day);
        return date;
    }

    getDateStringFullWithoutSplitterForMessageId(date) {

        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : "0" + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : "0" + day;

        var hour = date.getUTCHours().toString();
        hour = hour.length > 1 ? hour : "0" + hour;

        var minute = date.getMinutes().toString();
        minute = minute.length > 1 ? minute : "0" + minute;

        var second = date.getSeconds().toString();
        second = second.length > 1 ? second : "0" + second;

        var millisecond = date.getMilliseconds().toString();
        millisecond = millisecond.length > 1 ? millisecond : "0" + millisecond;

        return year + month + day + hour + minute + second + millisecond;
    }


    formatMessageTime(time: Date): string {
        const messageDate = new Date(time);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        if (this.isSameDate(messageDate, today)) {
            return ''
        } else if (this.isSameDate(messageDate, yesterday)) {
            return this.languageService.currentLanguage.key === 'tr' ? 'Dün' : 'Yesterday';
        } else {
            return messageDate.toLocaleDateString(this.languageService.currentLanguage.key === 'tr' ? 'tr-TR' : 'en-US');
        }
    }

    private isSameDate(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() &&
               date1.getMonth() === date2.getMonth() &&
               date1.getDate() === date2.getDate();
    }
    getDateStringHourMinute = function (date) {
        if (!date) return "";
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        if (isNaN(date.getTime())) {
            return "";
        }
    
        var hour = date.getHours().toString();
        hour = hour.length > 1 ? hour : "0" + hour;
    
        var minute = date.getMinutes().toString();
        minute = minute.length > 1 ? minute : "0" + minute;
    
        return hour + ":" + minute;
    };
    

    getDateStringDate(date) {
        if (!this.isNullOrUndefined(date)) {
            var year = date.getFullYear();

            var month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;

            var day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;

            return day + "." + month + "." + year;
        }
        return "00.00.00";
    }

    getDateStringTime(date) {
        if (!this.isNullOrUndefined(date)) {
            var hour = date.getUTCHours().toString();
            hour = hour.length > 1 ? hour : "0" + hour;

            var minute = date.getMinutes().toString();
            minute = minute.length > 1 ? minute : "0" + minute;

            var second = date.getSeconds().toString();
            second = second.length > 1 ? second : "0" + second;

            return hour + ":" + minute + ":" + second;
        }
        return "00:00:00";
    }

    getDateStringMilisecond(date) {
        var millisecond = date.getMilliseconds().toString();
        millisecond = millisecond.length > 1 ? millisecond : "0" + millisecond;

        return millisecond;
    }

    getDateStringFull(date) {
        if (!this.isNullOrUndefined(date))
            return this.getDateStringDate(date) + " " + this.getDateStringTime(date) + "." + this.getDateStringMilisecond(date);
        else
            return "00.00.00 00:00:00.0";
    }

    getDateFullForsMsSql(date: Date) {
        return "/Date(" + date.getTime() + ")/";
    }

    newDate(d?: any) {

        var date = new Date();

        if (d !== null && d !== undefined) {
            date = new Date(d);
        }

        return date;

    }

    turkishFullDateStringToJsDate = function (dateStr) {
        //20.09.2018 10:41:30:010
        //2018-09-20T10:41:39.010Z

        //tek formata dönüştür
        try {
            var strSplit = dateStr.split(' ');
            var date = strSplit[0];
            var dateSplit = date.split('.');

            var newDateStr = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];

            if (strSplit.length > 1) {

                var time = strSplit[1];
                var timeSplit = time.split(':');

                newDateStr += "T" + timeSplit[0] + ":" + timeSplit[1] + ":" + timeSplit[2];

                if (timeSplit.length === 3)
                    newDateStr += "Z";
                else
                    newDateStr += "." + timeSplit[3] + "Z";
            }

            let date2 = new Date(newDateStr);
            return date2;
        } catch (error) {
            return null;
        }
    }

    getDateFromJson(jsonDate) { // jsonDate=  "/Date(1520942583420+0300)/"
        try {
            // if (jsonDate.indexOf("Date(") !== -1)
            //return _helper.NewDate(new Date(parseInt(jsonDate.substr(6))));
            if (jsonDate.indexOf("Date(") !== -1) {

                let dateString = jsonDate.substr(6);
                let date = new Date(parseInt(dateString));
                let gmt = parseInt(dateString.split("+")[1].split(")")[0].substr(0, 2))

                date.setMilliseconds(date.getMilliseconds() + (gmt * 60 * 60 * 1000));

                //return date;
                return new Date(date.getUTCFullYear(),
                    date.getUTCMonth(),
                    date.getUTCDate(),
                    date.getUTCHours(),
                    date.getUTCMinutes(),
                    date.getUTCSeconds()
                );


            }


            return null;
        }
        catch (error) {
            return null;
        }
    }

    addServerTimeDistanceToDate(date: Date, serverTimeDistance: number) {

        try {
            var tempDate = new Date(date.getTime());

            tempDate.setMilliseconds(tempDate.getMilliseconds() + serverTimeDistance);

            return tempDate;
        }
        catch (error) {
            return null;
        }
    }

    //#endregion



    convertImgToBase64URL(url) {
        return new Promise((resolve, reject) => {
            try {
                var xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        resolve(reader.result);
                    }
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            } catch (error) {
                reject(error);
            }
        });
    }


    getStateIcons(value) {
        let iconUrl = Image.path + value + ".png";
        return iconUrl;
    }

    getArray(count: number) {
        if (count < 0) {
            return Array(0);
        }
        return Array(count);
    }

    resizeImage(base64Str, maxWidth, maxHeight, img): Promise<any> {
        return new Promise((resolve) => {
            //let img = new Image()
            img.src = base64Str
            img.onload = () => {
                let canvas = document.createElement('canvas')
                const MAX_WIDTH = maxWidth
                const MAX_HEIGHT = maxHeight
                let width = img.width
                let height = img.height

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                }
                canvas.width = width
                canvas.height = height
                let ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, width, height)
                resolve(canvas.toDataURL())
            }
        })
    }

    characterControl(text: string, min: number, max: number) {
        var result = true;
        if (!this.isNullOrEmtpyString(text)) {
            if (text.length < min || text.length > max)
                result = false;
        }
        else
            result = false;

        return result;
    }

    isValidUserName(userName) {
        if (userName === null || userName === undefined || userName === "")
            return false;

        if (userName.length < 3)
            return false;

        if(userName.length > 13)
            return false;

        return true;
    }

    getStarStr(starCount) {
        var stars = "";
        for (var i = 0; i < starCount; i++)
            stars += "*";

        return stars;
    }

    getRutbeOrangeImgageUrl(rank) {
        return this.GetImageUrl("RankOrange" + rank + ".png");
    }


    sortRankByDegree(a: GetRutbeInfo, b: GetRutbeInfo) {
        if (a.Derece < b.Derece) {
            return 1;
        }
        if (a.Derece > b.Derece) {
            return -1;
        }
        return 0;
    }

    openApp() {
        window.open("https://play.google.com/store/apps/details?id=com.seslidunya.app2", "_blank");
    }

    refreshPage() {
        document.location.reload()
    }

    isHTML(str) : boolean{
        var doc = new DOMParser().parseFromString(str, "text/html");
        if(doc.head.querySelector('meta') !== null && doc.head.querySelector('meta') !== undefined) {
            return true;
        }
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
    }
}
