import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/core/models/constants';
import { RoomUser, RoomUserContextMenuMode, RoomUserContextMenuOperation } from 'src/app/core/models/room-user';
import { Friend, UserFullInfo } from 'src/app/core/models/user';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomUserListService } from 'src/app/core/services/room-user-list-service';
import { RoomService } from 'src/app/core/services/room.service';
import { UtilService } from 'src/app/core/services/util.service';
import { faUserPlus, faUserCheck, faArrowAltCircleRight, faMicrophoneAltSlash, faMicrophoneAlt, faCamera, faHandPointer, faEnvelope, faBan, faVolumeMute, faFlag as faFlagMessage } from '@fortawesome/free-solid-svg-icons';
import { faComments, faHandPaper, faFlag, faIdCard, faHandshake } from '@fortawesome/free-regular-svg-icons';
import { BanPanel } from 'src/app/core/models/ban-panel';
import { BanOrMute, BulkMessageType, ChatType, FriendListType, SikayetTipleri } from 'src/app/core/models/enums';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';
import { AdminService } from 'src/app/admin/services/admin.service';
import { DynamicMethodsSendService } from 'src/app/core/services/dynamic-methods-send.service';
import { ReportService } from 'src/app/core/services/report.service';
import { TranslateService } from '@ngx-translate/core';
import { FriendshipService } from 'src/app/core/services/friendship.service';
import { ConfirmationService } from 'primeng/api';
import { FriendListService } from 'src/app/core/services/friendlist.service';
import { DynamicMethodsService } from 'src/app/core/services/dynamic-methods.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

  faComments = faComments;
  faBan = faBan;
  faVolumeMute = faVolumeMute;
  faCamera = faCamera;
  faMicrophoneAlt = faMicrophoneAlt;
  faMicrophoneAltSlash = faMicrophoneAltSlash;
  faArrowAltCircleRight = faArrowAltCircleRight;
  faHandPaper = faHandPaper;
  faIdCard = faIdCard;
  faHandshake = faHandshake;
  faFlag = faFlag;
  faFlagMessage = faFlagMessage;
  faHandPointer = faHandPointer;
  faEnvelope = faEnvelope;
  faUserPlus = faUserPlus;
  faUserCheck = faUserCheck;

  loaded: boolean = false;
  goToTheRoomVisible: boolean = false;
  isSiteBanMute: boolean;

  isMyFriend: boolean = false;
  isSendPrivateMessage: boolean = false;
  meInRoom: RoomUser;
  banOrMute = BanOrMute;

  RoomUserContextMenuMode = RoomUserContextMenuMode;
  roomUserContextMenuOperation: RoomUserContextMenuOperation;
  @ViewChild('userCard', { static: false, read: ElementRef }) userCard: ElementRef;

  constructor(
    public panelManagerService: PanelManagerService,
    public roomService: RoomService,
    public indexedDBService: IndexedDBService,
    public utilService: UtilService,
    private messagePanelService: MessagePanelService,
    private roomUserListService: RoomUserListService,
    private chatMessageService: ChatMessageService,
    private translateService: TranslateService,
    private adminService: AdminService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private reportService: ReportService,
    private friendshipService: FriendshipService,
    private confirmationService: ConfirmationService,
    private friendListService: FriendListService,
    private dynamicMethodsService:DynamicMethodsService
  ) {
    //ben hangi listedeyim ? 
    // this.meInRoom = this.roomService.vipUserList.find(f => f.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId);
    // this.meInRoom = this.roomService.getRoomUserFromActiveListByUserId(this.indexedDBService.userFullInfo.KullaniciId)
    if (this.roomService.meInRoom) {
      this.meInRoom = roomService.meInRoom
    }
    this.roomUserContextMenuOperation = new RoomUserContextMenuOperation();
  }

  ngOnInit(): void {
    this.setUserCard();
  }

  closeUserCard() {
    this.panelManagerService.roomUserCardPopupDisplay = false;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.userCard.nativeElement.contains(event.target))
      this.closeUserCard();
  }

  setUserCard() {
    this.isSendPrivateMessage =
      (this.roomService.selectedRoomUser.KullaniciId !== this.indexedDBService.userFullInfo.KullaniciId
        &&
        this.controlCommonMenuItems()
      );

    this.isMyFriend = this.indexedDBService.isMyFriend(this.roomService.selectedRoomUser.KullaniciId);
    // #region BAN_ODA, removeBanOda, BAN_SITE, removeBanSite, MUTE_ODA, removeMuteOda, MUTE_SITE, removeMuteSite
    this.roomUserContextMenuOperation.banOda =
      (
        this.authorityControlOnlyAuthorizedInternal('BAN_ODA') === true &&
        this.roomService.selectedRoomUser.BanOda === false &&
        this.controlCommonMenuItems()
      );

    this.roomUserContextMenuOperation.removeBanOda =
      (
        this.authorityControlOnlyAuthorizedInternal('BAN_ODA') === true &&
        this.roomService.selectedRoomUser.BanOda === true &&
        this.controlCommonMenuItems()
      );

    this.roomUserContextMenuOperation.banSite =
      (
        this.authorityControlOnlyAuthorizedInternal('BAN_SITE') === true &&
        this.roomService.selectedRoomUser.BanSite === false &&
        this.controlCommonMenuItems()
      );

    this.roomUserContextMenuOperation.removeBanSite =
      (
        this.authorityControlOnlyAuthorizedInternal('BAN_SITE') === true &&
        this.roomService.selectedRoomUser.BanSite === true &&
        this.controlCommonMenuItems()
      );

    this.roomUserContextMenuOperation.muteOda =
      (
        this.authorityControlOnlyAuthorizedInternal('MUTE_ODA') === true &&
        this.roomService.selectedRoomUser.MuteOda === false &&
        this.controlCommonMenuItems()
      );

    this.roomUserContextMenuOperation.removeMuteOda =
      (
        this.roomService.selectedRoomUser.KullaniciId != this.indexedDBService.userFullInfo.KullaniciId &&
        this.authorityControlOnlyAuthorizedInternal('MUTE_ODA') === true &&
        this.roomService.selectedRoomUser.MuteOda === true ||
        this.roomService.selectedRoomUser.MuteOdaList?.find(r => r === this.roomService.currentRoom.Info.ID) &&
        this.controlCommonMenuItems()
      );
      //there is a bug here,user can see own delete mute oda operation
      if (this.roomService.selectedRoomUser.KullaniciId == this.indexedDBService.userFullInfo.KullaniciId) {
        this.roomUserContextMenuOperation.removeMuteOda = false
      }

    this.roomUserContextMenuOperation.muteSite =
      (
        this.authorityControlOnlyAuthorizedInternal('MUTE_SITE') === true &&
        this.roomService.selectedRoomUser.MuteSite === false &&
        this.controlCommonMenuItems()
      );

    this.roomUserContextMenuOperation.removeMuteSite =
      (
        this.authorityControlOnlyAuthorizedInternal('MUTE_SITE') === true &&
        this.roomService.selectedRoomUser.MuteSite === true &&
        this.controlCommonMenuItems()
      );

    // #endregion
    this.roomUserContextMenuOperation.kameraSonlandir =
      (
        this.authorityControlOnlyAuthorizedInternal('KAMERA_SONLANDIR') === true &&
        this.utilService.isNullOrEmtpyObject(this.roomService.selectedRoomUser.RoomCamSession) === false &&
        this.roomService.selectedRoomUser.RoomCamSession !== this.utilService.GuidEmpty() &&
        this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomUserList
      );

    this.roomUserContextMenuOperation.mikrofonDusurme = false; // başta false diye setledik... aşağıda true olarak setlenmediği takdirde false olarak kalır.. 
    if (this.utilService.isNullOrEmtpyString(this.meInRoom.OnSecondMic) || this.meInRoom.OnSecondMic === false) { //ben ikinci mictekiysem, kimsenin mic düşüremem
      if (
        this.utilService.isNullOrEmtpyObject(this.roomService.oldmicrophoneJsonStruct) === false &&
        this.roomService.oldmicrophoneJsonStruct.M === this.meInRoom.KullaniciId.toString() &&
        this.utilService.isNullOrEmtpyObject(this.roomService.oldmicrophoneJsonStruct.M2) === false &&
        this.roomService.oldmicrophoneJsonStruct.M2.find(f => f === this.roomService.selectedRoomUser.KullaniciId.toString()) !== undefined &&
        this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomUserList
      ) { //main micteki bensem ve karşımdaki ikinci micteyse, rutbesine bakılmaksızın micini düşürebilirim.. 
        this.roomUserContextMenuOperation.mikrofonDusurme = true;
      }
      else if (
        this.authorityControlOnlyAuthorizedInternal('MIKROFON_DUSURME') === true &&
        this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomUserList &&
        this.utilService.isNullOrEmtpyObject(this.roomService.oldmicrophoneJsonStruct) === false &&
        this.roomService.oldmicrophoneJsonStruct.M === this.roomService.selectedRoomUser.KullaniciId.toString()
      ) { //main ve ikinci micteki ben değilsem, rütbe ve diğer koşullara göre karşımdakinin micini düşürebilirim..
        this.roomUserContextMenuOperation.mikrofonDusurme = true;
      }
    }

    this.roomUserContextMenuOperation.atmaOda =
      (
        this.authorityControlOnlyAuthorizedInternal('ATMA_ODA') === true &&
        this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomUserList
      );

    this.roomUserContextMenuOperation.elIndir =
      (
        this.authorityControlOnlyAuthorizedInternal('EL_INDIR') === true &&
        this.roomService.selectedRoomUser.HandUp === true &&
        this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomUserList
      );

    if (
      this.roomService.currentRoom.Info.MICLIMIT > 1 &&
      this.roomService.selectedRoomUser.KullaniciId !== this.indexedDBService.userFullInfo.KullaniciId &&
      this.utilService.isNullOrEmtpyObject(this.roomService.oldmicrophoneJsonStruct) === false &&
      this.roomService.oldmicrophoneJsonStruct.M === this.indexedDBService.userFullInfo.KullaniciId.toString() &&
      this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomUserList
    ) { // davet edebilmek için temel koşulların hepsi tamamsa:
      if (this.utilService.isNullOrEmtpyObject(this.roomService.oldmicrophoneJsonStruct.M2) === true) { //ikinci mic boşsa davet edebiliriz
        this.roomUserContextMenuOperation.inviteToMic = true;
      }
      else { //ikinci mic doluysa:
        if (
          this.roomService.oldmicrophoneJsonStruct.M2.find(a => a === this.roomService.selectedRoomUser.KullaniciId.toString()) === undefined &&
          this.roomService.currentRoom.Info.MICLIMIT > this.roomService.oldmicrophoneJsonStruct.M2.length + 1
        ) { // kullanıcı ikinci micte değilse ve mic limitinde yer varsa davet edebiliriz
          this.roomUserContextMenuOperation.inviteToMic = true;
        } else { // kullanıcı zaten ikinci micteyse yada mic limiti doluysa davet edemeyiz
          this.roomUserContextMenuOperation.inviteToMic = false;
        }
      }
    }
    else { // davet edebilmek için temel koşullardan en az biri eksikse davet edemeyiz..
      this.roomUserContextMenuOperation.inviteToMic = false;
    }

    this.roomUserContextMenuOperation.writePrivateMessage =
      (
        (
          this.roomService.selectedRoomUser.KullaniciId !== this.indexedDBService.userFullInfo.KullaniciId &&
          this.controlAuthorityInternal("OZEL_MESAJ", true) === true &&
          this.controlAllCommonMenuItems() &&
          this.indexedDBService.containsBlockedByMeUsers(this.roomService.selectedRoomUser.KullaniciId) === false &&
          this.indexedDBService.containsBlockingMeUsers(this.roomService.selectedRoomUser.KullaniciId) === false &&
          !(
            this.roomService.selectedRoomUser.RutbeAdi === "User" &&
            this.indexedDBService.userFullInfo.RutbeFullInfo.ADI === "User"
          )
        )
        ||
        (this.isMyFriend)
      );

    this.roomUserContextMenuOperation.odayaDavet = (
      this.roomService.selectedRoomUser.KullaniciId !== this.indexedDBService.userFullInfo.KullaniciId &&
      this.indexedDBService.userFullInfo.RutbeFullInfo['ODAYA_DAVET'] === true &&
      this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.ViewUsersInRoom
    );

    this.goToTheRoomVisible = (
      this.roomService.selectedRoomUser.KullaniciId !== this.indexedDBService.userFullInfo.KullaniciId &&
      this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.ViewUsersInRoom
    )

    this.loaded = true;
  }

  authorityControlOnlyAuthorizedInternal(authorityTag: string): boolean {
    if (
      this.meInRoom.BanSite === false &&
      this.meInRoom.MuteSite === false &&
      this.meInRoom.BanOda === false &&
      this.meInRoom.MuteOda === false &&
      this.indexedDBService.userFullInfo.KullaniciId !== this.roomService.selectedRoomUser.KullaniciId &&
      this.indexedDBService.userFullInfo.RutbeFullInfo.DERECE > this.roomService.selectedRoomUser.RutbeDerece &&
      this.indexedDBService.userFullInfo.RutbeFullInfo[authorityTag] === true
    ) {
      return true;
    }

    return false;
  }

  /* controlRoomActionBubbleMenuItems(): boolean {
     return (
       (this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomUserList)
       ||
       (this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomChatBubble)
       ||
       (this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomActionBubble)
     );
   }*/

  controlCommonMenuItems(): boolean {
    return (
      (this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomUserList)
      ||
      (this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomChatBubble)
    );
  }

  controlAuthorityInternal(authorityTag: string, rutbeDereceIsNotImportant: boolean): boolean {
    if (
      (
        this.roomService.selectedRoomUser.KullaniciId !== this.indexedDBService.userFullInfo.KullaniciId &&
        this.roomService.selectedRoomUser.RutbeId === Constants.UYERUTBEID
      )
      ||
      (
        this.indexedDBService.userFullInfo.RutbeFullInfo[authorityTag] === true &&
        (
          rutbeDereceIsNotImportant === true ? true : (this.indexedDBService.userFullInfo.RutbeFullInfo.DERECE > this.roomService.selectedRoomUser.RutbeDerece)
        )

      )
    )
      return true;

    return false;
  }

  controlAllCommonMenuItems(): boolean {
    return (
      (this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomUserList)
      ||
      (this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomChatBubble)
      ||
      (this.roomService.roomUserContextMenuMode === RoomUserContextMenuMode.ViewUsersInRoom)
    );
  }

  // #region writePrivateMessage
  writePrivateMessage() {
    let friend: Friend = this.indexedDBService.getFriendById(this.roomService.selectedRoomUser.KullaniciId);
    if (this.utilService.isNullOrEmtpyObject(friend)) {
      // var selectedRoomUser = this.roomService.roomUserList.find(f => f.KullaniciId === this.roomService.selectedRoomUser.KullaniciId);
      var selectedRoomUser = this.roomService.getRoomUserFromActiveListByUserId(this.roomService.selectedRoomUser.KullaniciId);
      if (this.utilService.isNullOrEmtpyObject(selectedRoomUser)) {
        // Arkadaşınız olmayan kullanıcılara aynı odada olmadığınız sürece mesaj atamazsınız!
        this.messagePanelService.ShowPopupMessageWithLocalization('[' + this.roomService.selectedRoomUser.KullaniciAdi + '] ' + this.utilService.getKeyWithTag('cantFİrstSendMessage'), 0, true)
        //roomService.selectedRoomUser = this.roomService.roomUserListForViewTheRoom.find(f => f.KullaniciId === this.roomService.selectedRoomUser.KullaniciId);
      }
      else {
        this.panelManagerService.openP2PChatPanel(this.roomService.selectedRoomUser.KullaniciId, this.roomUserListService.convertRoomUserToPtoPChatUser(this.roomService.selectedRoomUser));
      }
      // friend = this.roomUserListService.convertRoomUserToPtoPChatUser(roomService.selectedRoomUser);
      // this.indexedDBService.Friends.push(friend);
    }
    else
      this.panelManagerService.openP2PChatPanel(this.roomService.selectedRoomUser.KullaniciId, this.roomUserListService.convertRoomUserToPtoPChatUser(this.roomService.selectedRoomUser));
  }
  //#endregion

  //#region addRemoveFriend
  addRemoveFriend() {
    if (this.isMyFriend) {
      // Remove Friend
      this.confirmationService.confirm({
        message: '[' + this.roomService.selectedRoomUser.KullaniciAdi + '] ' + this.translateService.instant('BeforeRemoveFriendQuestion'),
        header: this.translateService.instant('removeFriend'),
        icon: null,
        accept: () => {
          this.friendListService.callRemoveFriend(this.roomService.selectedRoomUser.KullaniciId, FriendListType.Friends);
        },
        reject: () => {
        }
      });
    }
    else {
      // Add Friend
      this.confirmationService.confirm({
        message: '[' + this.roomService.selectedRoomUser.KullaniciAdi + '] ' + this.translateService.instant('BeforeAddUserToFriend'),
        header: this.translateService.instant('addfriend'),
        icon: null,
        accept: () => {
          this.friendshipService.callIsAddFriendActive(this.roomService.selectedRoomUser.KullaniciId)
            .then((isActiveAddFriend) => {
              if (isActiveAddFriend) {
                this.friendshipService.callSendFriendshipRequest(this.roomService.selectedRoomUser.KullaniciId);
              }
              else {
                this.messagePanelService.ShowPopupMessageWithLocalization("NotActiveAddFriend", 4000);
              }
            })
        },
        reject: () => {
        }
      });
    }
  }
  //#endregion

  // #region openBanMutePanel
  openBanMutePanel(banMode, banType, banOrMute) {

    this.indexedDBService.banPanel = new BanPanel();
    this.indexedDBService.banPanel.BanOrMute = banOrMute === 0 ? BanOrMute.Ban : BanOrMute.Mute;
    this.indexedDBService.banPanel.BanType = banType;
    this.indexedDBService.banPanel.UserId = this.roomService.selectedRoomUser.KullaniciId;
    this.indexedDBService.banPanel.BanMode = banMode;
    this.indexedDBService.banPanel.UserName = this.roomService.selectedRoomUser.KullaniciAdi;
    this.panelManagerService.banPanelPopupDisplay = true;
    this.roomService.selectedRoomUser = this.roomService.selectedRoomUser;
  }
  // #endregion

  // #region RemoveBans

  removeBanMute(isSiteBanMute, banOrMute) {
    this.isSiteBanMute = isSiteBanMute;

    if (banOrMute === BanOrMute.Ban)
      this.removeBan();

    if (banOrMute === BanOrMute.Mute)
      this.removeMute();
  }

  removeBan() {
    let body = {
      'ClientInfo': null,
      'userId': this.roomService.selectedRoomUser.KullaniciId,
      'RoomId': this.isSiteBanMute ? null : this.roomService.currentRoom.Info.ID,
      'IsBlackList': false
    };

    this.roomUserListService.deleteBanBlackList(body)
      .subscribe(response => {
        if (this.utilService.isNullOrUndefined(response)) {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        }

        if (!response) {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        } else {
          this.removeBanSuccess(response);
        }
        //TODO ikon çıkar engellendiği zaman / TODO engelle nince ikon koyuyor mu test et
      },
        error => {
          console.log(error);
        });
  }

  removeBanSuccess(deleteBanBlackListResponse) {
    try {
      var adminActionMessage = "'" + this.roomService.selectedRoomUser.KullaniciAdi + "' ";
      var adminActionTarget = "";
      var userSettingMessage = [];

      var userIds: Array<number> = deleteBanBlackListResponse.UserIds.split(',').map(function (userid) {
        return parseInt(userid, 0);
      });
      //userIds.pop();

      if (this.isSiteBanMute === false) {
        this.roomService.selectedRoomUser.BanOda = false;
        adminActionMessage += this.utilService.getKeyWithTag("UserRoomBanRemoved") + " (" + this.roomService.currentRoom.Info.NAME + ")";
        adminActionTarget = this.utilService.getKeyWithTag('RemoveUserRoomBan');
        if (userIds.length <= 0) {
          userSettingMessage = [this.dynamicMethodsSendService.KullaniciOdaBanKaldir(this.roomService.selectedRoomUser.KullaniciId, this.roomService.currentRoom.Info.ID)];
        }
      }
      if (this.isSiteBanMute === true) {
        this.roomService.selectedRoomUser.BanSite = false;
        adminActionMessage += this.utilService.getKeyWithTag("UserGeneralBanRemoved");
        adminActionTarget = this.utilService.getKeyWithTag('RemoveUserGeneralBan');
        if (userIds.length <= 0) {
          userSettingMessage = [this.dynamicMethodsSendService.KullaniciSiteBanKaldir(this.roomService.selectedRoomUser.KullaniciId)];
        }
      }

      this.adminService.callSaveAdminActions(adminActionMessage, adminActionTarget);

      // this.roomService.roomUserListChangedSource.next(this.roomService.selectedRoomUser.KullaniciId);
      this.roomService.refreshCurrentUserListPage();


      this.chatMessageService.sendAdminBulkMessageToPublicChannel(adminActionMessage, Constants.BulkMessageUnnecessaryId, BulkMessageType.AllRooms, ChatType.AdminChangesMessage);


      if (userIds.length > 0) {
        for (var i = 0; i < userIds.length; i++) {
          if (!this.isSiteBanMute) {
            //buraya dikkat etmek lazım
            userSettingMessage = [this.dynamicMethodsSendService.KullaniciOdaBanKaldir(userIds[i], this.roomService.currentRoom.Info.ID)];
            // this.chatMessageService.sendUserSettingMessage(userIds[i], userSettingMessage, this.roomService.selectedRoomUser.KullaniciAdi);
            let operationMessageForKullaniciOdaBanKaldir = this.dynamicMethodsService.prepareUserSettingMessage(
              userIds[i],
              userSettingMessage,
              this.roomService.selectedRoomUser.KullaniciAdi
              )
            this.dynamicMethodsService.publishDynKullaniciOdaBanKaldir(this.indexedDBService.userFullInfo.KullaniciId,userIds[i],operationMessageForKullaniciOdaBanKaldir);
          }
          else {
            userSettingMessage = [this.dynamicMethodsSendService.KullaniciSiteBanKaldir(userIds[i])];
           // this.chatMessageService.sendUserSettingMessage(userIds[i], userSettingMessage, this.roomService.selectedRoomUser.KullaniciAdi);
           let operationMessageForKullaniciSiteBanKaldir = this.dynamicMethodsService.prepareUserSettingMessage(
            userIds[i], 
            userSettingMessage, 
            this.roomService.selectedRoomUser.KullaniciAdi
            )
          this.dynamicMethodsService.publishDynKullaniciSiteBanKaldir(this.indexedDBService.userFullInfo.KullaniciId,userIds[i],operationMessageForKullaniciSiteBanKaldir);
          
          }
        }
      }
      else {
        //this.chatMessageService.sendUserSettingMessage(this.roomService.selectedRoomUser.KullaniciId, userSettingMessage, this.roomService.selectedRoomUser.KullaniciAdi);
        if (userSettingMessage.some(method => method.MethodName === "KullaniciOdaBanKaldir"))  {
          let operationMessageForKullaniciOdaBanKaldir = this.dynamicMethodsService.prepareUserSettingMessage(
            this.roomService.selectedRoomUser.KullaniciId,
            userSettingMessage,
            this.roomService.selectedRoomUser.KullaniciAdi
            )
          this.dynamicMethodsService.publishDynKullaniciOdaBanKaldir(this.indexedDBService.userFullInfo.KullaniciId,this.roomService.selectedRoomUser.KullaniciId,operationMessageForKullaniciOdaBanKaldir);
        }
        if (userSettingMessage.some(method => method.MethodName === "KullaniciSiteBanKaldir")) {
          let operationMessageForKullaniciSiteBanKaldir = this.dynamicMethodsService.prepareUserSettingMessage(
            this.roomService.selectedRoomUser.KullaniciId,
            userSettingMessage,
            this.roomService.selectedRoomUser.KullaniciAdi
            )
          this.dynamicMethodsService.publishDynKullaniciSiteBanKaldir(this.indexedDBService.userFullInfo.KullaniciId,this.roomService.selectedRoomUser.KullaniciId,operationMessageForKullaniciSiteBanKaldir);
        }
      }



    } catch (error) {
      console.log(error);
    }
  }

  removeMute() {

    let deleteMuteBody = {
      'ClientInfo': null,
      'UserId': this.roomService.selectedRoomUser.KullaniciId,
      'RoomId': this.isSiteBanMute ? null : this.roomService.currentRoom.Info.ID
    };
    this.roomUserListService.deleteMute(deleteMuteBody)
      .subscribe(response => {
        if (this.utilService.isNullOrUndefined(response)) {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        }

        if (!response) {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        } else {
          this.removeMuteSuccess();
        }
      },
        error => {
          console.log(error);
        });



  }

  removeMuteSuccess() {

    var banType: string = "";
    var adminActionMessage = "'" + this.roomService.selectedRoomUser.KullaniciAdi + "' ";
    var adminActionTarget = "";
    var userSettingMessage = [];

    if (this.isSiteBanMute === false) {
      this.roomService.selectedRoomUser.MuteOda = this.roomService.selectedRoomUser.MuteOda = false;
      adminActionMessage += this.utilService.getKeyWithTag("UserRoomMuteRemoved") + " (" + this.roomService.currentRoom.Info.NAME + ")";
      adminActionTarget = this.utilService.getKeyWithTag('RemoveUserRoomMute');
      userSettingMessage = [this.dynamicMethodsSendService.KullaniciOdaMuteKaldir(this.roomService.selectedRoomUser.KullaniciId, this.roomService.currentRoom.Info.ID)];
      banType = this.utilService.getKeyWithTag("UserRoomMuteRemoved") + " (" + this.roomService.currentRoom.Info.NAME + ")";
    }
    if (this.isSiteBanMute === true) {
      this.roomService.selectedRoomUser.MuteSite = this.roomService.selectedRoomUser.MuteSite = false;
      adminActionMessage += this.utilService.getKeyWithTag("UserGeneralMuteRemoved");
      adminActionTarget = this.utilService.getKeyWithTag('RemoveUserGeneralMute');
      userSettingMessage = [this.dynamicMethodsSendService.KullaniciSiteMuteKaldir(this.roomService.selectedRoomUser.KullaniciId)];
      banType = this.utilService.getKeyWithTag("UserGeneralMuteRemoved");
    }

    this.adminService.callSaveAdminActions(adminActionMessage, adminActionTarget);

    // this.roomService.roomUserListChangedSource.next(this.roomService.selectedRoomUser.KullaniciId);
    this.roomService.refreshCurrentUserListPage();


    /*  var roomPublicMessage = "'" + this.roomService.selectedRoomUser.KullaniciAdi.charAt(0) + "..." + this.roomService.selectedRoomUser.KullaniciAdi.charAt(this.roomService.selectedRoomUser.KullaniciAdi.length - 1) + "' " + banType
      this.chatMessageService.sendAdminBulkMessageToPublicChannel(roomPublicMessage, Constants.BulkMessageUnnecessaryId, BulkMessageType.AllRooms, ChatType.AdminChangesMessage);
  */
    //this.chatMessageService.sendUserSettingMessage(this.roomService.selectedRoomUser.KullaniciId, userSettingMessage, this.roomService.selectedRoomUser.KullaniciAdi);

    if (userSettingMessage.some(method => method.MethodName === "KullaniciOdaMuteKaldir")) {
      let operationMessageForKullaniciOdaMuteKaldir = this.dynamicMethodsService.prepareUserSettingMessage(
        this.roomService.selectedRoomUser.KullaniciId, 
        userSettingMessage,
        this.roomService.selectedRoomUser.KullaniciAdi
        )
      this.dynamicMethodsService.publishDynKullaniciOdaMuteKaldir(this.indexedDBService.userFullInfo.KullaniciId,this.roomService.selectedRoomUser.KullaniciId,operationMessageForKullaniciOdaMuteKaldir);
    }
    if (userSettingMessage.some(method => method.MethodName === "KullaniciSiteMuteKaldir")) {
      let operationMessageForKullaniciSiteMuteKaldir = this.dynamicMethodsService.prepareUserSettingMessage(
        this.roomService.selectedRoomUser.KullaniciId, 
        userSettingMessage,
        this.roomService.selectedRoomUser.KullaniciAdi
        )
      this.dynamicMethodsService.publishDynKullaniciSiteMuteKaldir(this.indexedDBService.userFullInfo.KullaniciId,this.roomService.selectedRoomUser.KullaniciId,operationMessageForKullaniciSiteMuteKaldir);
    }
    this.messagePanelService.ShowPopupMessageWithLocalization('OperationSuccess', 2000);
  }

  // #endregion

  //#region invite to the mic
  inviteToMic() {
    this.roomService.inviteToMicrophoneRequest(this.roomService.selectedRoomUser.KullaniciId);
  }
  //#endregion


  // #region finishCam
  finishCam() {
    // this.chatMessageService.sendRoomSettingMessage(this.roomService.currentRoom.Info.ID,
    //   [this.dynamicMethodsSendService.FinishCam(this.roomService.selectedRoomUser.KullaniciId, this.roomService.currentRoom.Info.ID, true)]
    // );

    let operationMessageForFinishCam = this.dynamicMethodsService.prepareRoomSettingMessage(
      this.roomService.currentRoom.Info.ID,
      [this.dynamicMethodsSendService.FinishCam(this.roomService.selectedRoomUser.KullaniciId, this.roomService.currentRoom.Info.ID, true)])
      this.dynamicMethodsService.publishDynFinishCam(this.roomService.currentRoom.Info.ID,operationMessageForFinishCam)

    this.chatMessageService.sendAdminBulkMessageToPublicChannel(
      this.roomService.selectedRoomUser.KullaniciAdi + " " + this.translateService.instant('DropCam'),
      Constants.BulkMessageUnnecessaryId,
      BulkMessageType.AllRooms,
      ChatType.AdminChangesMessage
    );

    this.messagePanelService.ShowPopupMessageWithLocalization("OperationSuccess", 2000);
  }
  // #endregion

  // #region stopUserMic
  stopUserMic() {
    try {
      if (this.roomService.selectedRoomUser.RutbeAdi != 'SipUser') {
        this.roomService.stopUserMicrophoneRequest(this.roomService.selectedRoomUser.KullaniciId, this.roomService.currentRoom.Info.ID);
      }
      else {
        this.roomService.stopSipUserMicrophoneRequest(this.roomService.selectedRoomUser.KullaniciId, this.roomService.currentRoom.Info.ID);
      }
      // this.roomService.stopUserMicrophoneRequest(this.roomService.selectedRoomUser.KullaniciId, this.roomService.currentRoom.Info.ID);

      var adminActionMessage = "'" + this.roomService.selectedRoomUser.KullaniciAdi + "' " +
        this.utilService.getKeyWithTag("UserMicrophoneStopped") +
        " (" + this.indexedDBService.getRoomNameById(this.roomService.currentRoom.Info.ID) + ")";

      var adminActionTarget = this.utilService.getKeyWithTag("StopUserMicrophone");
      this.adminService.callSaveAdminActions(adminActionMessage, adminActionTarget);

      this.chatMessageService.sendAdminBulkMessageToPublicChannel(adminActionMessage, Constants.BulkMessageUnnecessaryId, BulkMessageType.AllRooms, ChatType.AdminChangesMessage);

    }
    catch (error) {
      console.log(error);
    }
  }
  // #endregion

  // #region kickFromRoom
  kickFromRoom() {
    try {
      // önce kullanıcı micte ise o sonlandırılır ve o şekilde odadan atılır...
      if (this.roomService.oldmicrophoneJsonStruct.M === this.roomService.selectedRoomUser.KullaniciId.toString() ||
        (
          !this.utilService.isNullOrUndefined(this.roomService.oldmicrophoneJsonStruct.M2) &&
          this.roomService.oldmicrophoneJsonStruct.M2.find(userid => userid === this.roomService.selectedRoomUser.KullaniciId.toString())
        )) {
        this.roomService.stopUserMicrophoneRequest(this.roomService.selectedRoomUser.KullaniciId, this.roomService.currentRoom.Info.ID)
          .then(() => {
            this.kickFromRoomOperations();
          })
      }
      else {
        this.kickFromRoomOperations();
      }

    }
    catch (error) {
      console.log(error);
    }
  }

  kickFromRoomOperations() {
    let body = {
      'ClientInfo': null,
      'UserId': this.roomService.selectedRoomUser.KullaniciId,
      'RoomId': this.roomService.currentRoom.Info.ID
    };

    this.roomUserListService.kickFromRoom(body)
      .subscribe(response => {
        if (this.utilService.isNullOrUndefined(response)) {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        }

        if (response === true) {
          var adminActionMessage = this.roomService.selectedRoomUser.KullaniciAdi + this.utilService.getKeyWithTag("UserKickedOut") + " (" + this.roomService.currentRoom.Info.NAME + ")";
          var adminActionTarget = this.utilService.getKeyWithTag("KickOutUser");
          this.adminService.callSaveAdminActions(adminActionMessage, adminActionTarget);
          // kick from room
          // this.chatMessageService.sendUserSettingMessage(this.roomService.selectedRoomUser.KullaniciId,
          //   [this.dynamicMethodsSendService.OdadanAt(this.roomService.selectedRoomUser.KullaniciId, this.roomService.currentRoom.Info.ID)],
          //   this.roomService.selectedRoomUser.KullaniciAdi);

          let operationMessageForOdadanAt = this.dynamicMethodsService.prepareUserSettingMessage(
            this.roomService.selectedRoomUser.KullaniciId,
            [this.dynamicMethodsSendService.OdadanAt(this.roomService.selectedRoomUser.KullaniciId, this.roomService.currentRoom.Info.ID)],
            this.roomService.selectedRoomUser.KullaniciAdi
          )  
          this.dynamicMethodsService.publishDynOdadanAt(this.indexedDBService.userFullInfo.KullaniciId,this.roomService.selectedRoomUser.KullaniciId,operationMessageForOdadanAt)
         
          this.messagePanelService.ShowPopupMessageWithLocalization("OperationSuccess", 2000);

          this.roomUserListService.roomUserRemovedSource.next(this.roomService.selectedRoomUser.KullaniciId);
        } else {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        }
      })
  }
  // #endregion

  // #region dropUserHand
  dropUserHand() {
    let body = {
      'UserId': this.roomService.selectedRoomUser.KullaniciId.toString(),
      'RoomId': this.roomService.currentRoom.Info.ID.toString(),
      'Session': this.indexedDBService.userFullInfo.Token,
      "RequesterUserId": this.indexedDBService.userFullInfo.KullaniciId.toString()

    }
    this.roomService.adminHandDown(body)
      .toPromise()
      .then((response) => {
        if (this.utilService.isNullOrUndefined(response) === true || response !== "OK") {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        }

        var adminActionMessage = "'" + this.roomService.selectedRoomUser.KullaniciAdi + "' " + this.utilService.getKeyWithTag("UserHandIsDown") + " (" + this.indexedDBService.getRoomNameById(this.roomService.currentRoom.Info.ID) + ")";
        var adminActionTarget = this.utilService.getKeyWithTag("PutUserHandDown");
        this.adminService.callSaveAdminActions(adminActionMessage, adminActionTarget);

        //admin hareketi odalra mesaj olarak gönderilir
        this.chatMessageService.sendAdminBulkMessageToPublicChannel(adminActionMessage, Constants.BulkMessageUnnecessaryId, BulkMessageType.AllRooms, ChatType.AdminChangesMessage);

        this.messagePanelService.ShowPopupMessageWithLocalization('OperationSuccess', 2000);
      })
      .catch(error => {
        console.log(error);
      });
  }
  // #endregion

  //#region go to the room
  goToTheRoom() {
    this.goToTheRoomAndInviteToTheRoomOperations();
    if (this.roomService.viewingRoomInfo) {
      this.roomService.enterRoomNew(this.roomService.viewingRoomInfo?.ID);
    }
  }
  //#endregion

  //#region invite to the room
  inviteToTheRoom() {
    this.goToTheRoomAndInviteToTheRoomOperations();
    // this.chatMessageService.sendUserSettingMessage(
    //   this.roomService.selectedRoomUser.KullaniciId,
    //   [
    //     this.dynamicMethodsSendService.InviteToRoom(this.roomService.currentRoom.Info.ID,
    //       this.roomService.currentRoom.Info.NAME, this.indexedDBService.userFullInfo.KullaniciAdi)
    //   ],
    //   this.roomService.selectedRoomUser.KullaniciAdi
    // );

    let operationMessageForInviteToRoom = this.dynamicMethodsService.prepareUserSettingMessage(
      this.roomService.selectedRoomUser.KullaniciId,
      [
        this.dynamicMethodsSendService.InviteToRoom(this.roomService.currentRoom.Info.ID,
          this.roomService.currentRoom.Info.NAME, this.indexedDBService.userFullInfo.KullaniciAdi)
      ],
      this.roomService.selectedRoomUser.KullaniciAdi)
     this.dynamicMethodsService.publishDynInviteToRoom(this.indexedDBService.userFullInfo.KullaniciId,this.roomService.selectedRoomUser.KullaniciId,operationMessageForInviteToRoom);
  }
  //#endregion

  //#region go to the room & invite to the room operations
  goToTheRoomAndInviteToTheRoomOperations() {
    this.panelManagerService.viewTheRoomPopupDisplay = false;
    this.roomService.roomUserListForViewTheRoom = [];
    //this.roomUserListService.userViewInRoom = {};
  }
  //#endregion

  //#region complain
  complainUser() {
    this.reportService.header = this.roomService.selectedRoomUser.KullaniciAdi;
    this.reportService.insertComplainRequest.ComplainType = SikayetTipleri.Kullanici;
    this.reportService.insertComplainRequest.ComplainingId = this.indexedDBService.userFullInfo.KullaniciId;
    this.reportService.insertComplainRequest.ComplainedId = this.roomService.selectedRoomUser.KullaniciId;
    this.reportService.insertComplainRequest.Html = "";
    this.reportService.reportPopupDisplay = true;
  }

  complainMessage() {
    this.reportService.header = this.roomService.selectedRoomUser.KullaniciAdi;
    this.reportService.insertComplainRequest.ComplainType = SikayetTipleri.OdaMesaj;
    this.reportService.insertComplainRequest.ComplainingId = this.indexedDBService.userFullInfo.KullaniciId;
    this.reportService.insertComplainRequest.ComplainedId = this.roomService.selectedRoomUser.KullaniciId;
    this.reportService.insertComplainRequest.Html = this.reportService.reportedMessageContent;
    this.reportService.reportPopupDisplay = true;
  }
  //#endregion

  showProfilePicture() {
    this.indexedDBService.friendProfilePicture = this.utilService.getProfilePhotoUrl(this.roomService.selectedRoomUser.ProfilResmiId, true);
    this.panelManagerService.profilePhotoPopupDisplay = true;
  }
}
