export const sttModelData = {
    "models": [
        {"model_name": "Bulgarian", "model_code": "bg"},
        {"model_name": "Catalan", "model_code": "ca"},
        {"model_name": "Chinese Simplified", "model_code": "zh"},
        {"model_name": "Chinese Traditional", "model_code": "zh-TW"},
        {"model_name": "Czech", "model_code": "cs"},
        {"model_name": "Danish", "model_code": "da"},
        {"model_name": "Dutch", "model_code": "nl"},
        {"model_name": "English", "model_code": "en"},
        {"model_name": "Estonian", "model_code": "et"},
        {"model_name": "Finnish", "model_code": "fi"},
        {"model_name": "Flemish", "model_code": "nl"},
        {"model_name": "French", "model_code": "fr"},
        {"model_name": "German", "model_code": "de"},
        {"model_name": "German Switzerland", "model_code": "de-CH"},
        {"model_name": "Greek", "model_code": "el"},
        {"model_name": "Hindi", "model_code": "hi"},
        {"model_name": "Hungarian", "model_code": "hu"},
        {"model_name": "Indonesian", "model_code": "id"},
        {"model_name": "Italian", "model_code": "it"},
        {"model_name": "Japanese", "model_code": "ja"},
        {"model_name": "Korean", "model_code": "ko"},
        {"model_name": "Latvian", "model_code": "lv"},
        {"model_name": "Lithuanian", "model_code": "lt"},
        {"model_name": "Malay", "model_code": "ms"},
        {"model_name": "Norwegian", "model_code": "no"},
        {"model_name": "Polish", "model_code": "pl"},
        {"model_name": "Portuguese", "model_code": "pt"},
        {"model_name": "Romanian", "model_code": "ro"},
        {"model_name": "Russian", "model_code": "ru"},
        {"model_name": "Slovak", "model_code": "sk"},
        {"model_name": "Spanish", "model_code": "es"},
        {"model_name": "Swedish", "model_code": "sv"},
        {"model_name": "Thai", "model_code": "th"},
        {"model_name": "Turkish", "model_code": "tr"},
        {"model_name": "Ukrainian", "model_code": "uk"},
        {"model_name": "Vietnamese", "model_code": "vi"},
        {"model_name": "Multi Language", "model_code": "whisper"}
    ]
};


// { "model_code": "Multi Language", "model_name": "whisper" }
